const GoogleAuthReducer = (state, action) => {
    switch (action.type) {
        case "GOOGLEAUTH_START":
            return {
                googleAuth: null,
                isFetchingGoogleAuth: true,
                error: false,
            };
        case "GOOGLEAUTH_SUCCESS":
            return {
                googleAuth: action.payload,
                isFetchingGoogleAuth: false,
                error: false,
            };
        case "GOOGLEAUTH_FAILURE":
            return {
                googleAuth: null,
                isFetchingGoogleAuth: false,
                error: true,
            };
        default:
            return { ...state };
    }
}

export default GoogleAuthReducer;