import React, { createContext, useReducer } from "react";

import ProfileReducer from "./ProfileReducer";

const INITIAL_STATE = {
    profile: null,
    isFetchingProfile: false,
    error: false,
};

export const ProfileContext = createContext(INITIAL_STATE);

export const ProfileContextProvider = ({ children }) => {
    const [state, dispatchProfile] = useReducer(ProfileReducer, INITIAL_STATE);

    return (
        <ProfileContext.Provider
            value={{
                profile: state.profile,
                isFetchingProfile: state.isFetchingProfile,
                error: state.error,
                dispatchProfile
            }}
        >
            {children}
        </ProfileContext.Provider>
    )
}