import React, { useState, useEffect } from 'react';
import {
    Danger,
    CloseCircle
} from 'iconsax-react';

import './localAlert.scss';

const LocalAlert = ({ str }) => {
    const [isOpen, setIsOpen] = useState(true);
    const [display, setDisplay] = useState('flex');

    useEffect(() => {
        if (isOpen === true) {
            setDisplay('flex');
        } else if (isOpen === false) {
            setTimeout(function () {
                setDisplay('none');
            }, 700);
        }
    }, [isOpen]);

    function NewlineText(props) {
        const text = props.text;
        const newText = text.split('\n').map(str => <span className='alertTxt'>{str}</span>);

        return newText;
    }

    return (
        <div className={isOpen ? 'localAlert' : 'localAlert close'} style={{ display: display }}>
            <div className='iconContain'>
                <Danger className='alertIcon' size={40} />
            </div>
            <div className='txtContain'>
                <NewlineText text={str} />
            </div>
            <div className='iconCloseContain' onClick={() => setIsOpen(!isOpen)}>
                <CloseCircle className='closeIcon' size={25} />
            </div>
        </div>
    )
};

export default LocalAlert;