import React, { useEffect, useState, useContext, useRef } from 'react';
// import Chart, {
//   Legend,
//   Series,
//   CommonSeriesSettings,
// } from 'devextreme-react/chart';
import { BarGauge, Label, Tooltip, Size } from 'devextreme-react/bar-gauge';
import {
  Apple,
  Adb
} from "@mui/icons-material";
import {
  CpuSetting,
  MoneyRecive,
  ArrowSwapHorizontal,
  Notification,
  HambergerMenu
} from 'iconsax-react';
import { useNavigate } from 'react-router-dom';
import BounceLoader from "react-spinners/BounceLoader";
import { css } from "@emotion/react";

import './dashboard.scss';
// import { populationData } from './data.js';
import { EmailContext } from '../../context/emailContext/EmailContext';
import { UserPackContext } from '../../context/userPackContext/UserPackContext';
import { getUserPacks } from '../../context/userPackContext/apiCalls';

const override = css`
  display: block;
`;

const palette = ['#78c4ba'];

const values = [30, 100, 70];
const barColors = ['#1DA9E5', '#13CC68', '#FFD057'];

const Dashboard = ({ menu, setMenu }) => {
  const [loading, setLoading] = useState(true);
  const [clabel, setClabel] = useState(null);
  const [clabel2, setClabel2] = useState(null);
  const [botDate, setBotDate] = useState(0);
  const [portfolio, setPortfolio] = useState([]);
  const [botNum, setBotNum] = useState(0);
  const [capital, setCapital] = useState(0);
  const [trans, setTrans] = useState(0);

  const { user, dispatch } = useContext(EmailContext);
  const { userPack, dispatchUserPack, isFetchingUserPack } = useContext(UserPackContext);

  const navigate = useNavigate();

  const fetch = useRef(true);
  useEffect(() => {
    if (fetch.current) {
      fetch.current = false;

      getUserPacks(dispatchUserPack, user.token, navigate, dispatch);
    }
  }, []);
  useEffect(() => {
    if (userPack) {
      let temp = 0;
      let x = 0;
      let xx = 0;
      setPortfolio([]);

      for (let i = 0; i < userPack.length; i++) {
        setPortfolio((portfolio) => [...portfolio, parseFloat(userPack[i].profit)]);

        if (userPack[i].botstatus) {
          temp++;
        }
        x += userPack[i].trades;
        xx += parseFloat(userPack[i].fund);
      }
      setBotNum(temp);
      setTrans(x);
      setCapital(xx);
    }
  }, [userPack]);

  function customizeTooltip(arg) {
    return {
      text: getText(arg, arg.valueText),
    };
  }
  function customizeTooltip2(arg) {
    return {
      text: getText2(arg, arg.valueText),
    };
  }

  function getText(item, text) {
    setClabel(item.value);
    return ` `;
  }
  function getText2(item, text) {
    setClabel2(item.value + '%')
    return `Robot@name ${text}%`;
  }

  const handleLicense = (second) => {
    const first = Date.parse(new Date());
    setBotDate(Math.round((second - first) / (1000 * 60 * 60 * 24)))
  }

  return (
    <div className='dashboard'>
      <div className={menu ? 'mask' : 'mask close'} />

      <div className='top'>
        <div className='topFirst'>
          <div className='left'>
            <div className='leftContent'>
              <span className='title'>Welcome Back</span>
            </div>
          </div>

          <div className='right'>
            <div className='rightContent'>
              <div className='profileContain'>
                <div className='imgContain'>
                  <div className='img'>
                    <img
                      src={
                        !loading ?
                          window.$url + `/${user.avatar}`
                          :
                          require('../../images/avatar.png')}
                      alt={user.fullName}
                      onLoad={() => setLoading(false)}
                    />
                  </div>
                </div>
              </div>
              <div className='infoContain'>
                <span className='name'>
                  {user.fullName}
                </span>
                <span className='email'>
                  {user.email}
                </span>
              </div>
              <div className='notifContain'>
                <div className='combine'>
                  <Notification className='icon' size={20} />
                  <div className='dot' />
                </div>
              </div>
            </div>
          </div>

          <div className='leftMobile'>
            <HambergerMenu size="32" className='menuBtn' onClick={() => setMenu(true)} />
          </div>

          <div className='middleMobile' />

          <div className='rightMobile'>
            <div className='profileContain'>
              <div className='imgContain'>
                <div className='img'>
                  <img
                    src={
                      !loading ?
                        window.$url + `/${user.avatar}`
                        :
                        require('../../images/avatar.png')}
                    alt={user.fullName}
                    onLoad={() => setLoading(false)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='topSecond'>
          <div className='card'>
            <div className='left'>
              <CpuSetting className='icon' variant="Bold" size={50} />
            </div>
            <div className='right'>
              <span className='title'>Robo Man</span>
              <span className='status'>{botNum} active robot</span>
            </div>
          </div>
          <div className='card'>
            <div className='left'>
              <CpuSetting className='icon' color='#fff' variant="Bold" size={50} />
            </div>
            <div className='right'>
              <span className='title'>Robo Man</span>
              <span className='status'>{userPack?.length - botNum} deactive robot</span>
            </div>
          </div>
          <div className='card'>
            <div className='left'>
              <MoneyRecive className='icon' variant="Bold" size={50} />
            </div>
            <div className='right'>
              <span className='title'>Capital Volume</span>
              <span className='status'>{capital}s</span>
            </div>
          </div>
          <div className='card'>
            <div className='left'>
              <ArrowSwapHorizontal className='icon' variant="Bold" size={50} />
            </div>
            <div className='right'>
              <span className='title'>Transactions</span>
              <span className='status'>{trans}tr</span>
            </div>
          </div>
        </div>
      </div>

      <div className='middle'>
        <div className='left'>
          <img className='chartImg' alt={'fx Chart'} src={require('../../images/diagram.png')} />
          {/* <div className='chartContain'>
            <div className='topChart'>
              <div className='leftChart'>
                All robots activity
              </div>
              <div className='rightChart'>
                <span className='tabs'>
                  Days
                </span>
                <span className='tabs'>
                  Weeks
                </span>
                <span className='tabs'>
                  Months
                </span>
                <span className='tabs'>
                  Years
                </span>
              </div>
            </div>
            <div className='bottom'>
              <Chart
                dataSource={populationData}
                id="chart"
              >
                <Size width={'50%'} height={300} />
                <Series
                  type="bar"
                />

                <Legend
                  visible={false}
                />

                <CommonSeriesSettings
                  argumentField="arg"
                  type="bar"
                  barPadding={0.7}
                />
              </Chart>
            </div>
          </div> */}
          <div className='pieChartContain'>
            <div className='pieChart'>
              <div className='pieTop'>
                <span className='pieTitle'>Robot license</span>
                <span className='cat'>Robot@name</span>
              </div>

              <div className='pieBottom'>
                <BarGauge
                  id="gauge"
                  startValue={0}
                  endValue={360}
                  palette={palette}
                  defaultValues={botDate}
                  values={botDate}
                  relativeInnerRadius={0.6}
                  geometry={{ startAngle: 200, endAngle: 340 }}
                >
                  <Size width={200} height={250} />
                  <Label visible={false} />
                  <Tooltip enabled={true} customizeTooltip={customizeTooltip} opacity={0} />
                </BarGauge>

                <div className='labeContain'>
                  <h1>{clabel !== {} ? botDate : ''} Days</h1>
                </div>
              </div>

              <span className='pieBottomText'>Your robot will be automatically deactivated after the license expires</span>
            </div>

            <div className='pieChart2'>
              <div className='pieTop'>
                <span className='pieTitle'>Portfolio</span>
              </div>

              <div className='pieBottom'>
                <BarGauge
                  id="gauge"
                  startValue={0}
                  endValue={100}
                  defaultValues={portfolio}
                  values={portfolio}
                  geometry={{ startAngle: 90, endAngle: 450 }}
                  relativeInnerRadius={0.4}
                  palette={barColors}
                >
                  <Size width={200} height={250} />
                  <Label visible={false} />
                  <Tooltip enabled={true} customizeTooltip={customizeTooltip2} />
                </BarGauge>

                <div className='labeContain'>
                  <h1>{clabel2}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='right'>
          <div className='appContain'>
            <div className='iconContain'>
              <Apple className="icon" />
              <Adb className="icon" />
              <span className='iconTitle'>Web Application</span>
            </div>

            <div className='txtLine'>
              <span className='txt'>You can use our Application</span>
            </div>
            <div className='txtLine'>
              <span className='txt2'>For</span>
              <span className='txtGreen'>easy</span>
              <span className='txt2'>and</span>
              <span className='txtGreen'>fast access</span>
            </div>

            <img className='obj1' alt='obj1' src={require('../../images/rectangle1.png')} />
            <img className='obj2' alt='obj2' src={require('../../images/rectangle2.png')} />
            <img className='obj3' alt='obj3' src={require('../../images/rectangle3.png')} />
          </div>

          <div className='robotContain'>
            <span className='topTxt'>All robots status</span>

            <div className='robotColl'>
              <div className='collContain'>
                <div className='coll'>
                  <span className='topTxt'>Robots</span>
                </div>
                <div className='coll'>
                  <span className='topTxt'>Status</span>
                </div>
              </div>

              {!isFetchingUserPack ?
                userPack?.map((item, index) => (
                  <div className='contentContain' key={index}>
                    <div className='leftContent'>
                      <span className='robotTxt' onClick={() => handleLicense(item.endat)}>Robots@{item._id}</span>
                    </div>

                    <div className='rightContent'>
                      {
                        item.botstatus ?
                          <img className='dot' alt='dot' src={require('../../images/dot.png')} />
                          :
                          <span className='deactiveTxt'>Deactivated</span>

                      }
                    </div>
                  </div>
                ))
                :
                <div className='loadContain'>
                  <BounceLoader color={"#fff"} loading={true} css={override} size={15} />
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default Dashboard;