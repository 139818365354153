import React, { useState, useEffect, useRef, useContext } from 'react';
import {
    Bag2,
    Notification,
    ArrowLeft2,
    HambergerMenu,
    ArrowDown2,
    ArrowUp2,
} from 'iconsax-react';
import { useNavigate } from 'react-router-dom';
import BounceLoader from "react-spinners/BounceLoader";
import { css } from "@emotion/react";
import {
    DataGrid,
    gridPageCountSelector,
    gridPageSelector,
    useGridApiContext,
    useGridSelector,
} from '@mui/x-data-grid';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';

import './shopping.scss';
import ShoppingItem from '../../component/shoppingItem/ShoppingItem';
import PayDialog from '../../component/payDialog/PayDialog';
import fakeData from './data.json';

import { EmailContext } from '../../context/emailContext/EmailContext';
import { BasketContext } from '../../context/basketContext/BasketContext';
import { getBasket, deleteBasket } from '../../context/basketContext/apiCalls';
import { PayContext } from '../../context/payContext/PayContext';
import { addPay, getPay } from '../../context/payContext/apiCalls';

const override = css`
  display: block;
`;

const Shopping = ({ menu, setMenu }) => {
    const [loading, setLoading] = useState(true);
    const [productAmount, setProductAmount] = useState(0);
    const [fee, setFee] = useState(0);
    const [totalProductAmount, setTotalProductAmount] = useState(0);
    const [isPayDialog, setIsPayDialog] = useState(false);
    const [showAll, setShowAll] = useState(false);

    const data = fakeData.data;

    const { user, dispatch } = useContext(EmailContext);
    const { basket, dispatchBasket } = useContext(BasketContext);
    const { pay, dispatchPay, isFetchingPay } = useContext(PayContext);

    const navigate = useNavigate();

    const fetch = useRef(true);

    useEffect(() => {
        if (fetch.current) {
            fetch.current = false;

            getBasket(user.token, dispatchBasket, dispatch, navigate, setProductAmount, setFee, setTotalProductAmount);
            getPay(user.token, dispatchPay);
        }
    }, []);

    const handleRemove = (productID, isOpen, setIsOpen) => {
        deleteBasket(productID, user.token, dispatchBasket, dispatch, navigate, isOpen, setIsOpen, setProductAmount, setFee, setTotalProductAmount);
    }

    const checkOut = () => {
        // alert(user.token)
        addPay(user.token, dispatchPay, dispatch, navigate, setIsPayDialog);
    }

    const columns = [
        {
            field: "date",
            headerName: "Date",
            width: 130,
            headerClassName: 'headerData',
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                return (
                    <div className="listItem">
                        {(new Date(Date.parse(params.row.createdAt))).toUTCString().substring(4, 16)}
                    </div>
                );
            },
        },
        {
            field: "price",
            headerName: "Price",
            width: 300,
            headerClassName: 'headerData',
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                return (
                    <div className='listItem' style={{ color: '#13CC68' }}>
                        <span className='span'>{params.row.amount}$</span>
                    </div>
                );
            },
        },
        {
            field: "sendAddress",
            headerName: "Sent Address",
            width: 200,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                return (
                    <div className="listItemm">
                        {params.row.address}
                    </div>
                );
            },
        },
    ];

    function CustomPagination() {
        const apiRef = useGridApiContext();
        const page = useGridSelector(apiRef, gridPageSelector);
        const pageCount = useGridSelector(apiRef, gridPageCountSelector);

        return (
            <Pagination
                color="primary"
                variant="outlined"
                shape="rounded"
                page={page + 1}
                count={pageCount}
                style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    width: '100%',
                    direction: 'ltr',
                    color: '#fff'
                }}
                // @ts-expect-error
                renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
                onChange={(event, value) => apiRef.current.setPage(value - 1)}
            />
        );
    }

    return (
        <div className='shopping'>
            <div className={menu ? 'mask' : 'mask close'} />

            <div className='topFirst'>
                <div className='left'>
                    <Bag2 className="icon" size={60} />
                    <div className='titleContain'>
                        <span className='mainTitle'>Shopping</span>
                        <span className='moreTitle'>Your Shopping center</span>
                    </div>
                </div>
                <div className='right'>
                    <div className='rightContent'>
                        <button className='buyBtn' onClick={() => navigate('/plans')}>Buy Robot</button>
                        <div className='profileContain'>
                            <div className='imgContain'>
                                <div className='img'>
                                    <img
                                        src={
                                            !loading ?
                                                window.$url + `/${user.avatar}`
                                                :
                                                require('../../images/avatar.png')}
                                        alt={user.fullName}
                                        onLoad={() => setLoading(false)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='infoContain'>
                            <span className='name'>
                                {user.fullName}
                            </span>
                            <span className='email'>
                                {user.email}
                            </span>
                        </div>
                        <div className='notifContain'>
                            <div className='combine'>
                                <Notification className='icon' size={20} />
                                <div className='dot' />
                            </div>
                        </div>
                    </div>
                </div>

                <div className='leftMobile'>
                    <HambergerMenu size="32" className='menuBtn' onClick={() => setMenu(true)} />
                </div>
                <div className='middleMobile'>
                    <Bag2 className="icon" size={30} />
                </div>
                <div className='rightMobile'>
                    <div className='profileContain'>
                        <div className='imgContain'>
                            <div className='img'>
                                <img
                                    src={
                                        !loading ?
                                            window.$url + `/${user.avatar}`
                                            :
                                            require('../../images/avatar.png')}
                                    alt={user.fullName}
                                    onLoad={() => setLoading(false)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='shoppingContain'>
                <div className='productContain'>
                    <div className='headerContain'>
                        <div className='product'>
                            Product
                        </div>
                        <div className='quantity'>
                            Quantity
                        </div>
                        <div className='price'>
                            Total Price
                        </div>
                        <div className='delete' />
                    </div>

                    <div className='seperator' />

                    {basket?.map((item, index) => (
                        <ShoppingItem item={item} index={index} handleRemove={handleRemove} />
                    ))}

                    <div className='shoppingFooter'>
                        <div className='footerLeft'>
                            <button className='shoppingBtn' onClick={() => navigate('/plans')}>
                                <ArrowLeft2 className='iconBtn' />
                                Continue Shopping
                            </button>
                        </div>

                        <div className='footerRight'>
                            <div className='textContain'>
                                <div className='txtLeft'>
                                    <span className='txt'>Subtotal:</span>
                                    <span className='txt'>GateWay Fee (0.5%):</span>
                                </div>
                                <div className='txtRight'>
                                    <span className='txt'>${productAmount}</span>
                                    <span className='txt'>${fee}</span>
                                </div>
                            </div>

                            <div className='seperator' style={{ margin: '10px 0' }} />

                            <div className='textContain'>
                                <div className='txtLeft'>
                                    <span className='txt'>Total:</span>
                                </div>
                                <div className='txtRight'>
                                    <span className='txt'>${totalProductAmount}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='paymentContain'>
                    <span className='payInfo'>
                        Payment Info
                    </span>

                    <div className='seperator' />

                    <span className='payMethod'>
                        Payment Method:
                    </span>

                    <div className='coinpayConain'>
                        <img className='img' src={require('../../images/coinpaymentsLogo.png')} alt='CoinPayment Logo' />
                    </div>

                    <div className='buyContain'>
                        <button className='buyBtn' disabled={isFetchingPay} onClick={checkOut}>
                            {!isFetchingPay ?
                                'Check Out.'
                                :
                                <BounceLoader color={"#fff"} loading={true} css={override} size={15} />
                            }
                        </button>
                    </div>
                </div>
            </div>

            <div className='purchaseHistory'>
                Purchase history

                <DataGrid
                    pageSize={10}
                    rows={pay !== null && pay}
                    disableSelectionOnClick
                    columns={columns}
                    getRowId={r => r._id}
                    getRowHeight={() => 'auto'}
                    getEstimatedRowHeight={() => 200}
                    style={{
                        color: '#fff',
                        height: showAll ? '500px' : '300px',
                        backgroundColor: 'transparent',
                        overflowX: 'auto',
                        margin: '20px',
                        transition: 'height 0.5s ease-in-out',
                    }}
                    sx={{
                        border: 'none',
                        '&>.MuiDataGrid-main': {
                            '&>.MuiDataGrid-columnHeaders': {
                                borderColor: '#414141',
                            },
                            '&>.MuiDataGrid-columnFooter': {
                                borderColor: '#414141',
                            },
                            '& div div div div >.MuiDataGrid-cell': {
                                borderColor: '#414141'
                            },
                        },
                        '.MuiDataGrid-columnSeparator': {
                            display: 'none',
                        },
                        '&.MuiDataGrid-root': {
                            border: 'none',
                        },
                        "& .MuiPaginationItem-root": {
                            color: "#fff",
                        },
                    }}
                    components={{
                        NoRowsOverlay: () => (
                            <div className="noRows">
                                <span>
                                    No history
                                </span>
                            </div>
                        ),
                        Pagination: CustomPagination,
                    }}
                />

                <div className='showAll' onClick={() => setShowAll(!showAll)}>
                    {
                        showAll ?
                            <>
                                Show less
                                <ArrowUp2 className='showAllIcon' />
                            </>
                            :
                            <>
                                Show all
                                <ArrowDown2 className='showAllIcon' />
                            </>
                    }
                </div>
            </div>

            <PayDialog isPayDialog={isPayDialog} setIsPayDialog={setIsPayDialog} />
        </div>
    );
};

export default Shopping;