import React, { useState, useContext } from 'react';
import {
    ArrowForwardIos
} from '@mui/icons-material';
import {
    EmptyWallet,
    UserAdd,
    CloudConnection,
    Code1,
    TickSquare,
    CloseSquare
} from 'iconsax-react';
import { useNavigate } from "react-router-dom";
import BounceLoader from "react-spinners/BounceLoader";
import { css } from "@emotion/react";

import './planCard.scss';
import { EmailContext } from '../../context/emailContext/EmailContext';
import { BasketContext } from '../../context/basketContext/BasketContext';
import { addBasket } from '../../context/basketContext/apiCalls';

const override = css`
display: block;
`;

const PlanCard = ({ item, index }) => {
    const [count, setCount] = useState(1);

    const { user, dispatch } = useContext(EmailContext);
    const { dispatchBasket, isFetchingBasket } = useContext(BasketContext);

    const navigate = useNavigate();

    const handlePlus = () => {
        setCount(count + 1)
    }
    const handleMinus = () => {
        if (count > 1) {
            setCount(count - 1);
        }
    }

    const setHederColor = (type) => {
        switch (type) {
            case '0':
                return ('#898888');
            case '1':
                return ('#4D77E2');
            case '2':
                return ('#D7C100');
            default:
                return ('#898888');
        }
    }

    const addToCard = () => {
        if (user) {
            const basket = {
                productID: item._id,
                count: count
            };

            addBasket(basket, user.token, dispatchBasket, dispatch, navigate);
        } else {
            navigate('/login');
        }
    }

    return (
        <div className='planCard' key={index}>
            <div className='cardHeader' style={{ backgroundColor: setHederColor(item.type) }}>
                {item.name}
                <div className='triangle' style={{ backgroundColor: setHederColor(item.type) }} />
            </div>

            <div className='bodyContain'>
                <div className='first'>
                    <span className='other'>$</span>
                    <span className='price' style={{marginRight: '5px'}}>{item.price}</span>
                    <span className='other'>/ {item.expireDate} months</span>
                    <div className='line' />
                </div>
                <div className='second'>
                    <div className='infoContain'>
                        <div className='infoLeft'>
                            <EmptyWallet size="18" color="#ffffff" />
                        </div>
                        <div className='infoMiddle'>
                            Inventory limit
                        </div>
                        <div className='infoRight'>
                            {item.inventoryLimit}
                        </div>
                    </div>
                    <div className='infoContain'>
                        <div className='infoLeft'>
                            <UserAdd size="18" color="#ffffff" />
                        </div>
                        <div className='infoMiddle'>
                            {item.statusSupport ?
                                'Standard support'
                                :
                                'Basic support'
                            }
                        </div>
                        <div className='infoRight'>
                            <TickSquare className='infoIcon' size={20} style={{ color: '#13CC68' }} />
                        </div>
                    </div>
                    <div className='infoContain'>
                        <div className='infoLeft'>
                            <CloudConnection size="18" color="#ffffff" />
                        </div>
                        <div className='infoMiddle'>
                            Virtual server
                        </div>
                        <div className='infoRight'>
                            {item.statusVS ?
                                item.dateVS + '/month'
                                :
                                <CloseSquare className='infoIcon' size={20} style={{ color: '#CF1B26' }} />
                            }
                        </div>
                    </div>
                    <div className='infoContain'>
                        <div className='infoLeft'>
                            <Code1 size="18" color="#ffffff" />
                        </div>
                        <div className='infoMiddle'>
                            Setting up
                        </div>
                        <div className='infoRight'>
                            <TickSquare className='infoIcon' size={20} style={{ color: '#13CC68' }} />
                        </div>
                    </div>
                    <div className='infoContain'>
                        <div className='infoLeft'>
                            <Code1 size="18" color="#ffffff" />
                        </div>
                        <div className='infoMiddle'>
                            Access settings
                        </div>
                        <div className='infoRight'>
                            {item.statusAccSt ?
                                <TickSquare className='infoIcon' size={20} style={{ color: '#13CC68' }} />
                                :
                                <CloseSquare className='infoIcon' size={20} style={{ color: '#CF1B26' }} />
                            }
                        </div>
                    </div>

                    <div className='father'>
                        <div className='countContain'>
                            <button className='addBtn' onClick={handleMinus}>-</button>
                            <span className='count'>{count}</span>
                            <button className='addBtn' onClick={handlePlus}>+</button>
                        </div>
                    </div>
                </div>
                <div className='third'>
                    <button className='btn' onClick={addToCard} disabled={isFetchingBasket}>
                        Add to Card
                        <div className='icon'>
                            <ArrowForwardIos style={{ fontSize: '15px' }} />
                        </div>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default PlanCard;