import axios from "axios";
import { toast } from 'react-toastify';

import { basketFailure, basketStart, basketSuccess } from "./BasketActions";
import { logout } from '../emailContext/EmailActions';

export const addBasket = async (data, token, dispatch, dispatchEmail, navigate) => {
    dispatch(basketStart());

    axios.post(window.$url + "/api/user/basket/add-basket", { data }, {
        headers: {
            token
        }
    }).then((res) => {
        dispatch(basketSuccess(null));
        toast.success(res.data.message);
    }).catch((error) => {
        dispatch(basketFailure());

        if (error.response.data.errors.message) {
            toast.error(error.response.data.errors.message);

            if (error.response.data.errors.message === 'Please login') {
                dispatchEmail(logout());

                navigate('/')
            }
        } else {
            toast.error('Try again!');
        }
    });
}

export const getBasket = async (token, dispatch, dispatchEmail, navigate, setProductAmount, setFee, setTotalProductAmount) => {
    dispatch(basketStart());

    axios.get(window.$url + "/api/user/basket/basket-list", {
        headers: {
            token
        }
    }).then((res) => {
        dispatch(basketSuccess(res.data.data.result.productDetail));
        
        setProductAmount(res.data.data.result.payDetail.productAmount);
        setFee(res.data.data.result.payDetail.fee);
        setTotalProductAmount(res.data.data.result.payDetail.totalProductAmount);

        toast.success(res.data.message);
    }).catch((error) => {
        dispatch(basketFailure());

        if (error.response.data.errors.message) {
            toast.error(error.response.data.errors.message);

            if (error.response.data.errors.message === 'Please login') {
                dispatchEmail(logout());

                navigate('/')
            }
        } else {
            toast.error('Try again!');
        }
    });
}

export const deleteBasket = async (productID, token, dispatch, dispatchEmail, navigate, isOpen, setIsOpen, setProductAmount, setFee, setTotalProductAmount) => {
    axios.post(window.$url + "/api/user/basket/remove-basket", { productID }, {
        headers: {
            token
        }
    }).then((res) => {
        setIsOpen(!isOpen);

        console.log(res.data.data.result.payDetail)

        setProductAmount(res.data.data.result.payDetail.productAmount)
        setFee(res.data.data.result.payDetail.fee)
        setTotalProductAmount(res.data.data.result.payDetail.totalProductAmount)

        toast.success('Product has been successfully deleted!');
    }).catch((error) => {
        if (error.response.data.errors.message) {
            toast.error(error.response.data.errors.message);

            if (error.response.data.errors.message === 'Please login') {
                dispatchEmail(logout());

                navigate('/')
            }
        } else {
            toast.error('Try again!');
        }
    });
}