import React, { useEffect, useState, useContext, useRef } from 'react';
import {
    User,
    Sms,
    Subtitle,
    MessageText1
} from 'iconsax-react';
import { useTheme } from '@mui/material/styles';
import Input from '@mui/material/Input';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { useNavigate } from 'react-router-dom';
import BounceLoader from "react-spinners/BounceLoader";
import { css } from "@emotion/react";

import './ticketWrite.scss';
import fakeData from './fakeData.json';

import { EmailContext } from '../../../context/emailContext/EmailContext';
import { UserPackContext } from '../../../context/userPackContext/UserPackContext';
import { getUserPackName } from '../../../context/userPackContext/apiCalls';
import { TicketContext } from '../../../context/ticketContext/TicketContext';
import { addTicket } from '../../../context/ticketContext/apiCall';

const override = css`
  display: block;
`;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
            color: '#fff',
            backgroundColor: 'rgba(21, 21, 21, 0.8)',
            fontFamily: 'ru',
        },
    },
};

const department = [
    'Technical',
    'Withdraw'
];
const importance = [
    'Low',
    'Medium',
    'High'
];

function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

const TicketWrite = ({ setPageType, setConversationData }) => {
    const [data, setData] = useState([]);
    const [tickData, setTickData] = useState(null);
    const [depart, setDepart] = useState('');
    const [robo, setRobo] = useState('');
    const [impor, setImpor] = useState('');
    const [attach, setAttach] = useState(null);
    const [fileName, setFileName] = useState('No file choosen');

    const { user, dispatch } = useContext(EmailContext);
    const { userPack, dispatchUserPack, isFetchingUserPack } = useContext(UserPackContext);
    const { ticket, dispatchTicket, isFetchingTicket } = useContext(TicketContext);

    const theme = useTheme();

    useEffect(() => {
        if (fakeData) {
            setData(fakeData.data);
        }
    }, []);

    const navigate = useNavigate();

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setDepart(value)
        setTickData({ ...tickData, ['department']: value })
    };
    const handleRobotChange = (event) => {
        const {
            target: { value },
        } = event;
        setRobo(value);
        setTickData({ ...tickData, ['releventRobot']: value })
    };
    const handleImportanceChange = (event) => {
        const {
            target: { value },
        } = event;
        setImpor(value);
        setTickData({ ...tickData, ['importTick']: value })
    };

    const handleRowClick = (params) => {
        setConversationData(params);
        setPageType('conversation');
    };

    const fetch = useRef(true);
    const getNames = () => {
        if (fetch.current) {
            fetch.current = false;

            getUserPackName(dispatchUserPack, user.token, navigate, dispatch);
        }
    }

    const handleOpenTicket = () => {
        if (tickData !== null) {
            if (tickData.email === undefined || tickData.topic === undefined || tickData.department === undefined || tickData.releventRobot === undefined || tickData.importTick === undefined || tickData.text === undefined || tickData.email === '' || tickData.topic === '' || tickData.department === '' || tickData.releventRobot === '' || tickData.importTick === '' || tickData.text === '') {
                alert("Please complete all fields!");
            } else {
                addTicket(tickData, user.token, dispatchTicket, dispatch, navigate, attach);
            }
        } else {
            alert("Please complete all fields!");
        }
    }

    return (
        <div className='container'>
            <div className='ticketWrite1'>
                <div className='contain'>
                    <div className='part'>
                        <div className='iconContain'>
                            <User className='icon' />
                            <span className='txtTitle'>
                                Name and Lastname
                            </span>
                        </div>

                        <Input className='inputFirst' disableUnderline />
                    </div>

                    <div className='part'>
                        <div className='iconContain'>
                            <Sms className='icon' />
                            <span className='txtTitle'>
                                Email address
                            </span>
                        </div>

                        <Input
                            className='inputFirst'
                            name='email'
                            disableUnderline
                            onChange={(e) => setTickData({ ...tickData, [e.target.name]: e.target.value })}
                        />
                    </div>
                </div>

                <div className='contain'>
                    <div className='part' style={{ flex: 1 }}>
                        <div className='iconContain'>
                            <Subtitle className='icon' />
                            <span className='txtTitle'>
                                Your topic
                            </span>
                        </div>

                        <Input
                            className='inputFirst'
                            disableUnderline
                            placeholder='Enter your topic'
                            name='topic'
                            onChange={(e) => setTickData({ ...tickData, [e.target.name]: e.target.value })}
                        />
                    </div>
                </div>

                <div className='contain'>
                    <div className='part2'>
                        <div className='iconContain'>
                            Department
                        </div>

                        <Select
                            displayEmpty
                            value={depart}
                            onChange={handleChange}
                            input={<Input className='input' disableUnderline />}
                            MenuProps={MenuProps}
                            className={'select'}
                            inputProps={{ 'aria-label': 'Without label' }}
                        >
                            <MenuItem disabled value="">
                                <em style={{ color: '#767676' }}>Select</em>
                            </MenuItem>
                            {department.map((name) => (
                                <MenuItem
                                    key={name}
                                    value={name}
                                    style={getStyles(name, depart, theme)}
                                >
                                    {name}
                                </MenuItem>
                            ))}
                        </Select>
                    </div>

                    <div className='part2'>
                        <div className='iconContain'>
                            Relevant robot
                        </div>

                        <Select
                            displayEmpty
                            value={robo}
                            onChange={handleRobotChange}
                            input={<Input className='input' disableUnderline />}
                            MenuProps={MenuProps}
                            className={'select'}
                            inputProps={{ 'aria-label': 'Without label' }}
                            onOpen={getNames}
                        >
                            <MenuItem disabled value="">
                                <em style={{ color: '#767676' }}>Select</em>
                            </MenuItem>
                            {!isFetchingUserPack ?
                                userPack?.map((item) => (
                                    <MenuItem
                                        key={item.name}
                                        value={item.name}
                                        style={getStyles(item.name, robo, theme)}
                                    >
                                        {item.name}
                                    </MenuItem>
                                ))
                                :
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <BounceLoader color={"#fff"} loading={true} css={override} size={15} />
                                </div>
                            }
                            {!isFetchingUserPack && <MenuItem
                                key={'other'}
                                value={'other'}
                                style={getStyles('other', robo, theme)}
                            >
                                Other
                            </MenuItem>}
                        </Select>
                    </div>

                    <div className='part2'>
                        <div className='iconContain'>
                            Importance
                        </div>

                        <Select
                            displayEmpty
                            value={impor}
                            onChange={handleImportanceChange}
                            input={<Input className='input' disableUnderline />}
                            MenuProps={MenuProps}
                            className={'select'}
                            inputProps={{ 'aria-label': 'Without label' }}
                        >
                            <MenuItem disabled value="">
                                <em style={{ color: '#767676' }}>Select</em>
                            </MenuItem>
                            {importance.map((name) => (
                                <MenuItem
                                    key={name}
                                    value={name}
                                    style={getStyles(name, impor, theme)}
                                >
                                    {name}
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                </div>

                <div className='contain'>
                    <div className='part3' style={{ flex: 1 }}>
                        <TextareaAutosize
                            name='text'
                            placeholder="Enter your text"
                            className={'inputArea'}
                            style={{ height: '10rem' }}
                            onChange={(e) => setTickData({ ...tickData, [e.target.name]: e.target.value })}
                        />
                    </div>
                </div>

                <div className='contain'>
                    <div className='part' style={{ flex: 1 }}>
                        <div className='iconContain'>
                            Attachments
                        </div>

                        <input
                            type="file"
                            id="img"
                            name={"image"}
                            onChange={(e) => {
                                setAttach(e.target.files[0])
                                setFileName(e.target.files[0].name)
                            }}
                            hidden
                        />

                        <div className='inputContain'>
                            <button className='fileBtn' onClick={() => document.getElementById('img').click()}>
                                Choose File
                            </button>

                            <span className='fileTxt'>{fileName}</span>
                        </div>
                    </div>
                </div>

                <div className='footer'>
                    <button className='cancelBtn' onClick={() => setPageType('history')}>Cancel</button>
                    <button className='sendBtn' onClick={handleOpenTicket} disabled={isFetchingTicket}>
                        {!isFetchingTicket ?
                            'Send'
                            :
                            <BounceLoader color={"#fff"} loading={true} css={override} size={15} />
                        }
                    </button>
                </div>
            </div>

            <div className='ticketWrite2'>
                <div className='contain'>
                    <div className='iconContain'>
                        <MessageText1 className='icon' />
                        Your recent tickets
                    </div>
                    <div className='main'>
                        {
                            ticket && ticket.map((item, index) =>
                                <div className='ticketContain' key={index} onClick={() => handleRowClick(item)}>
                                    <span className='idd'>#{item.ticketNumber}</span>
                                    <span className='topic'>{item.topic}</span>
                                    <span className='id' style={{ color: item.statustick === 'Open' ? '#13CC68' : '#EA0F0F' }}>{item.statustick}</span>
                                    <span className='id'>{Date(item.updatedAt).substring(4, 15)}</span>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
};

export default TicketWrite;