import React from 'react';
import {
    Scan,
    Notification,
    TransmitSquare,
    HambergerMenu
} from 'iconsax-react';

import './verify.scss';
import LocalAlert from '../../component/localAlert/LocalAlert';

const Verify = ({ menu, setMenu }) => {
    const str1 = 'Your account email has not been verified. \n If you have not received the confirmation email, click on this link.';
    const str2 = 'You have not submitted the required documents to activate your account. \n You will not be able to make purchases from the site until your account documents are verified. \n Send the necessary documents to activate your account through this page, after review and approval by the management, you will be able to purchase from the site.';

    return (
        <div className='verify'>
            <div className={menu ? 'mask' : 'mask close'} />

            <div className='topFirst'>
                <div className='left'>
                    <Scan className="icon" size={60} />
                    <div className='titleContain'>
                        <span className='mainTitle'>Authentication</span>
                    </div>
                </div>
                <div className='right'>
                    <div className='rightContent'>
                        <button className='buyBtn'>Buy Robot</button>
                        <div className='profileContain'>
                            <div className='imgContain'>
                                <div className='img'>
                                    <img src={require('../../images/profile.png')} alt='profileImg' />
                                </div>
                            </div>
                        </div>
                        <div className='infoContain'>
                            <span className='name'>
                                Mahdi Pirzadeh
                            </span>
                            <span className='email'>
                                user@gmail.com
                            </span>
                        </div>
                        <div className='notifContain'>
                            <div className='combine'>
                                <Notification className='icon' size={20} />
                                <div className='dot' />
                            </div>
                        </div>
                    </div>
                </div>

                <div className='leftMobile'>
                    <HambergerMenu size="32" className='menuBtn' onClick={() => setMenu(true)} />
                </div>
                <div className='middleMobile'>
                    <Scan className="icon" size={30} />
                </div>
                <div className='rightMobile'>
                    <div className='profileContain'>
                        <div className='imgContain'>
                            <div className='img'>
                                <img src={require('../../images/profile.png')} alt='profileImg' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='mainContain'>
                <LocalAlert str={str1} />
                <LocalAlert str={str2} />
                <div className='videoImg' />
                <button className='recordBtn'>Start Recoeding</button>
                <span className='recGuide'>Please record a video of yourself while reading the following text aloud with a national card in hand and upload it for us.</span>
                <span className='someGuide'>im (Name and Lastname) …..  I have read and adhered to all the rules of use of the FXtrder robot and I take full responsibility for it with full knowledge of the risk of financial markets.</span>

                <div className='main'>
                    <div className='mainLeft'>
                        <span className='titleTxt'>Name and LastName</span>
                        <div className='inputContain'>
                            <input className='input' placeholder='Name and Lastname' maxLength={50} type='text' />
                        </div>
                        <span className='titleTxt'>National id number</span>
                        <div className='inputContain'>
                            <input className='input' placeholder='ID number' type="text" pattern="\d*" maxlength="10" />
                        </div>
                        <span className='titleTxt'>National card image</span>
                        <div className='inputContain'>
                            <button className='fileBtn'>Choose File</button>

                            <span className='fileTxt'>No file chosen</span>
                        </div>
                        <span className='fileExplainTxt'>Jpg, png extensions are allowed and the maximum image size is 512 KB.</span>
                    </div>
                    <div className='mainRight' />
                </div>

                <div className='footer'>
                    <button className='uploadBtn'>
                        Upload
                        <TransmitSquare className='uploadIcon' size={25} />
                    </button>
                </div>
            </div>
        </div>
    )
};

export default Verify;