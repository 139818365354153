import React, { createContext, useReducer } from "react";

import ReferralReducer from "./ReferralReducer";

const INITIAL_STATE = {
    referral: null,
    isFetchingReferral: false,
    error: false,
};

export const ReferralContext = createContext(INITIAL_STATE);

export const ReferralContextProvider = ({ children }) => {
    const [state, dispatchReferral] = useReducer(ReferralReducer, INITIAL_STATE);

    return (
        <ReferralContext.Provider
            value={{
                referral: state.referral,
                isFetchingReferral: state.isFetchingReferral,
                error: state.error,
                dispatchReferral
            }}
        >
            {children}
        </ReferralContext.Provider>
    )
}