import React, { useState, useContext } from 'react';
import {
    Danger,
    CloseSquare,
    EmptyWallet,
    ArrowCircleRight
} from 'iconsax-react';
import Checkbox from '@mui/material/Checkbox';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import BounceLoader from "react-spinners/BounceLoader";
import { css } from "@emotion/react";

import './withdrawDialog.scss';

import { EmailContext } from '../../context/emailContext/EmailContext';
import { CommissionHisContext } from '../../context/commissionHisContext/CommissionHisContext';
import { sendWithdraw } from '../../context/commissionHisContext/apiCalls';

const override = css`
  display: block;
`;

const WithdrawDialog = ({ openWithdraw, setOpenWithdraw }) => {
    const [checked, setChecked] = useState(false);
    const [address, setAddress] = useState(false);

    const { user, dispatch } = useContext(EmailContext);
    const { commissionHis, dispatchCommissionHis, isFetchingCommissionHis } = useContext(CommissionHisContext);

    const navigate = useNavigate();

    const handleSubmit = () => {
        if (address === '' || address === undefined || address === null) {
            toast.error('Please send currect address');
        } else {
            sendWithdraw(user?.token, dispatchCommissionHis, dispatch, navigate, commissionHis?.getReward, address, setOpenWithdraw)
        }
    }

    return (
        <div className={openWithdraw ? 'withdrawDialog' : 'withdrawDialog close'}>
            <div className='dialogContain'>
                <CloseSquare className='iconClose' onClick={() => setOpenWithdraw(false)} />

                <span className='topic'>Withdraw</span>
                <Danger className='icon' />

                <div className='mainContain'>
                    <div className='inputTitleContain'>
                        <EmptyWallet className='wallet' size={20} />
                        <span className='inputTitle'>TRC20 USDT wallet address</span>
                    </div>

                    <div className='inputContain'>
                        <input className='input' placeholder='Enter your wallet address' onChange={e => setAddress(e.target.value)} />
                    </div>

                    <div className='checkContain' onClick={() => setChecked(!checked)}>
                        <Checkbox
                            checked={checked}
                            sx={{
                                padding: 0,
                                color: '#13CC68',
                                '&.Mui-checked': {
                                    color: '#13CC68',
                                },
                            }}
                        />
                        <span className='checkLabel'>I'm sure I entered the correct address</span>
                    </div>
                </div>

                <button className='btn' disabled={isFetchingCommissionHis || !checked} onClick={handleSubmit}>
                    {!isFetchingCommissionHis ?
                        <>
                            Confirm
                            <ArrowCircleRight className='iconBtn' variant='Bold' />
                        </>
                        :
                        <BounceLoader color={"#fff"} loading={true} css={override} size={15} />
                    }
                </button>
            </div>
        </div>
    )
};

export default WithdrawDialog;