import React, { useState, useContext, useEffect, useRef } from 'react';
import {
  DataGrid,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import {
  People,
  Notification,
  DocumentCopy,
  HambergerMenu
} from 'iconsax-react';
import { useNavigate } from 'react-router-dom';
import BounceLoader from "react-spinners/BounceLoader";
import { css } from "@emotion/react";
import { toast } from 'react-toastify';

import './referral.scss';
import fakeData from './data.json';
import WithdrawDialog from '../../component/withdrawDialog/WithdrawDialog';

import { EmailContext } from '../../context/emailContext/EmailContext';
import { ReferralContext } from '../../context/referralContext/ReferralContext';
import { getReferral } from '../../context/referralContext/apiCalls';
import { CommissionHisContext } from '../../context/commissionHisContext/CommissionHisContext';
import { getCommissionHis } from '../../context/commissionHisContext/apiCalls';

const override = css`
  display: block;
`;

const code = '2KJFWW';
const link = 'https://fxtrader.cm/2kjfww';

const Referral = ({ menu, setMenu }) => {
  const [openWithdraw, setOpenWithdraw] = useState(false);
  const [loading, setLoading] = useState(true);

  const { user, dispatch } = useContext(EmailContext);
  const { referral, dispatchReferral, isFetchingReferral } = useContext(ReferralContext);
  const { commissionHis, dispatchCommissionHis, isFetchingCommissionHis } = useContext(CommissionHisContext);

  const navigate = useNavigate();

  const fetch = useRef(true);
  useEffect(() => {
    if (fetch.current) {
      fetch.current = false;

      getReferral(user.token, dispatchReferral, dispatch, navigate);
      getCommissionHis(user.token, dispatchCommissionHis, dispatch, navigate);
    }
  }, []);

  const data = fakeData.data;

  const copy = async (text) => {
    await navigator.clipboard.writeText(text);
    toast.success('Text copied');
  }

  const handleStatus = (checkout, received) => {
    if (checkout && received) {
      return (
        <div className="listItem" style={{ color: '#13CC68' }}>
          Received
        </div>
      );
    } else if (checkout && !received) {
      return (
        <div className="listItem" style={{ color: 'rgb(136, 136, 136)' }}>
          Pending
        </div>
      );
    } else if (!checkout && !received) {
      return (
        <div className="listItem" style={{ color: 'red' }}>
          Not Received
        </div>
      );
    }
  }

  const columns = [
    {
      field: "date",
      headerName: "Date",
      width: 90,
      headerClassName: 'headerData',
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        let time = new Intl.DateTimeFormat('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(new Date(params.row.createdAt));
        return (
          <div className="listItem">
            {time}
          </div>
        );
      },
    },
    {
      field: "registrationEmail",
      headerName: "Registration email",
      width: 600,
      headerClassName: 'headerData',
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        return (
          <div className='listItem'>
            <span className='span'>{params.row.buyerName}</span>
          </div>
        );
      },
    },
    {
      field: "receivedCommission",
      headerName: "Received commission",
      width: 200,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        return (
          <div className="listItem" style={{ color: '#13CC68' }}>
            {params.row.amount}$
          </div>
        );
      },
    },
    {
      field: "receivedStatus",
      headerName: "Received status",
      width: 200,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        let status = handleStatus(params.row.received, params.row.checkout);

        return status;
      },
    },
  ];

  function CustomPagination() {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
      <Pagination
        color="primary"
        variant="outlined"
        shape="rounded"
        page={page + 1}
        count={pageCount}
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          width: '100%',
          direction: 'ltr',
          color: '#fff'
        }}
        // @ts-expect-error
        renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
        onChange={(event, value) => apiRef.current.setPage(value - 1)}
      />
    );
  }

  return (
    <div className='referral'>
      <div className={menu ? 'mask' : 'mask close'} />
      <div className='topFirst'>
        <div className='left'>
          <People className="icon" size={60} />
          <div className='titleContain'>
            <span className='mainTitle'>Referral</span>
          </div>
        </div>
        <div className='right'>
          <div className='rightContent'>
            <button className='buyBtn'>Buy Robot</button>
            <div className='profileContain'>
              <div className='imgContain'>
                <div className='img'>
                  <img
                    src={
                      !loading ?
                        window.$url + `/${user.avatar}`
                        :
                        require('../../images/avatar.png')}
                    alt={user.fullName}
                    onLoad={() => setLoading(false)}
                  />
                </div>
              </div>
            </div>
            <div className='infoContain'>
              <span className='name'>
                {user.fullName}
              </span>
              <span className='email'>
                {user.email}
              </span>
            </div>
            <div className='notifContain'>
              <div className='combine'>
                <Notification className='icon' size={20} />
                <div className='dot' />
              </div>
            </div>
          </div>
        </div>

        <div className='leftMobile'>
          <HambergerMenu size="32" className='menuBtn' onClick={() => setMenu(true)} />
        </div>
        <div className='middleMobile'>
          <DocumentCopy className="icon" size={30} />
        </div>
        <div className='rightMobile'>
          <div className='profileContain'>
            <div className='imgContain'>
              <div className='img'>
                <img
                  src={
                    !loading ?
                      window.$url + `/${user.avatar}`
                      :
                      require('../../images/avatar.png')}
                  alt={user.fullName}
                  onLoad={() => setLoading(false)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='topicContain'>
        <div className='topicFirst'>
          Invite your friends and get a commission
        </div>

        <div className='topicSecond'>
          <div className='imgContain'>
            <img className='topicImg' src={require('../../images/referral.png')} alt='referral' />
          </div>

          <div className='topicInfoContain'>
            {/* <div className='topicInfo'>
              <div className='topicLeft'>
                Your referral code:
              </div>
              <div className='topicRight'>
                <div className='copyPart'>
                  <span onClick={() => copy(code)}>
                    {code}
                  </span>
                  <DocumentCopy className='copyIcon' color='#0088ea' />
                </div>
              </div>
            </div> */}

            <div className='topicInfo'>
              <div className='topicLeft'>
                Your referral link:
              </div>
              <div className='topicRight'>
                <button disabled={isFetchingReferral} className='copyPart' onClick={() => copy(referral)}>
                  <span>
                    {!isFetchingReferral ?
                      referral
                      :
                      <BounceLoader color={"#fff"} loading={true} css={override} size={15} />}
                  </span>
                  <DocumentCopy className='copyIcon' color='#0088ea' />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='commissionStatsContain'>
        <div className='commissionStats'>
          <span className='topicCommission'>Basic commission rate</span>
          <span className='valueCommission'>5%</span>

          <div className='btnWithdraw' style={{ backgroundColor: 'transparent', cursor: 'unset' }}></div>
        </div>
        <div className='commissionStats'>
          <span className='topicCommission'>Amount received</span>
          <span className='valueCommission'>
            {!isFetchingCommissionHis ?
              commissionHis?.getReward + '$'
              :
              <BounceLoader color={"#fff"} loading={true} css={override} size={15} />
            }
          </span>

          <div>
            <button className='btnWithdraw' disabled={(commissionHis?.getReward === 0 || commissionHis?.getReward === undefined) && true} onClick={() => setOpenWithdraw(true)}>Withdraw</button>
          </div>
        </div>
        <div className='commissionStats'>
          <span className='topicCommission'>Total invitations</span>
          <span className='valueCommission'>{!isFetchingCommissionHis ?
            commissionHis?.result.length :
            <BounceLoader color={"#fff"} loading={true} css={override} size={15} />
          }</span>

          <div className='btnWithdraw' style={{ backgroundColor: 'transparent', cursor: 'unset' }}></div>
        </div>
      </div>

      <div className='commissionHistory'>
        Your commission history

        <DataGrid
          pageSize={10}
          rows={commissionHis !== null && commissionHis.result}
          disableSelectionOnClick
          columns={columns}
          getRowId={r => r._id}
          getRowHeight={() => 'auto'}
          getEstimatedRowHeight={() => 200}
          style={{
            color: '#fff',
            height: '500px',
            backgroundColor: 'transparent',
            overflowX: 'auto',
            margin: '20px',
          }}
          sx={{
            border: 'none',
            '&>.MuiDataGrid-main': {
              '&>.MuiDataGrid-columnHeaders': {
                borderColor: '#414141',
              },
              '&>.MuiDataGrid-columnFooter': {
                borderColor: '#414141',
              },
              '& div div div div >.MuiDataGrid-cell': {
                borderColor: '#414141'
              },
            },
            '.MuiDataGrid-columnSeparator': {
              display: 'none',
            },
            '&.MuiDataGrid-root': {
              border: 'none',
            },
            "& .MuiPaginationItem-root": {
              color: "#fff",
            },
          }}
          components={{
            NoRowsOverlay: () => (
              <div className="noRows">
                <span>
                  No history
                </span>
              </div>
            ),
            Pagination: CustomPagination,
          }}
        />
      </div>

      <WithdrawDialog openWithdraw={openWithdraw} setOpenWithdraw={setOpenWithdraw} />
    </div>
  )
};

export default Referral;