import React, { useState, useEffect, useContext, useRef } from 'react';
import BounceLoader from "react-spinners/BounceLoader";
import { css } from "@emotion/react";
import {
    Notification,
    HambergerMenu,
    ArchiveBook,
    ShoppingCart
} from 'iconsax-react';
import { useNavigate } from 'react-router-dom';
import socketIOClient from "socket.io-client";

import { EmailContext } from '../../context/emailContext/EmailContext';
import './packList.scss';
import PlanCard from '../../component/planCard/PlanCard';

import { PlansContext } from '../../context/plansContext/PlansContext';
import { getPlans } from '../../context/plansContext/apiCalls';

const override = css`
display: block;
`;

const PackList = ({ menu, setMenu }) => {
    const [loading, setLoading] = useState(true);

    const { user } = useContext(EmailContext);
    const { plans, dispatchPlans, isFetching } = useContext(PlansContext);
    const [response, setResponse] = useState("");

    const navigate = useNavigate();

    const fetch = useRef(true);
    useEffect(() => {
        if (fetch.current) {
            fetch.current = false;

            getPlans(dispatchPlans);
        }
    }, []);

    useEffect(() => {

        // const socket = socketIOClient('http://192.168.1.107:4001', {
        //     transportOptions: {
        //         polling: {
        //             extraHeaders: {
        //                 token: user.token
        //             }
        //         }
        //     }
        // });
        // socket.on("connection", data => {
        //     setResponse(data);
        //     console.log(data)
        // });
        // socket.on("FromAPI", data => {
        //     setResponse(data);
        //     console.log(data)
        // });

        // const sockett = socketIOClient('http://192.168.1.107:3030', {
        //     transportOptions: {
        //         polling: {
        //             extraHeaders: {
        //                 token: user.token
        //             }
        //         }
        //     }
        // });
        // sockett.on("connection", data => {
        //     setResponse(data);
        //     console.log(data)
        // });
        // sockett.on("FromAPI", data => {
        //     setResponse(data);
        //     console.log(data)
        //     sockett.emit('dast', {data: '156'}, (status) => console.log(status))
        // });
    }, [])


    // const fetch = useRef(true);
    // useEffect(() => {
    //     if (fetch.current) {
    //         fetch.current = false;

    //         getPlans(dispatchPlans);
    //     }
    // }, []);

    // const xxx = () => {
    //     const socket = socketIOClient('http://192.168.1.107:3030', {
    //         transportOptions: {
    //             polling: {
    //                 extraHeaders: {
    //                     token: user.token
    //                 }
    //             }
    //         }
    //     });

    //     socket.emit('dast', 156)
    // }

    return (
        <div className={'packList'}>
            <div className={menu ? 'mask' : 'mask close'} />

            <div className='topFirst'>
                <div className='left'>
                    <ArchiveBook className="icon" size={60} />
                    <div className='titleContain'>
                        <span className='mainTitle'>Plans</span>
                        <span className='moreTitle'>Add plan to your shopping card</span>
                    </div>
                </div>
                <div className='right'>
                    <div className='rightContent'>
                        <div className='profileContain'>
                            <div className='imgContain'>
                                <div className='img'>
                                    <img
                                        src={
                                            !loading ?
                                                window.$url + `/${user.avatar}`
                                                :
                                                require('../../images/avatar.png')}
                                        alt={user.fullName}
                                        onLoad={() => setLoading(false)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='infoContain'>
                            <span className='name'>
                                {user.fullName}
                            </span>
                            <span className='email'>
                                {user.email}
                            </span>
                        </div>
                        <div className='notifContain' onClick={() => navigate('/shopping')}>
                            <div className='combine'>
                                <ShoppingCart className='icon' size={25} />
                            </div>
                        </div>
                        <div className='notifContain'>
                            <div className='combine'>
                                <Notification className='icon' size={20} />
                                <div className='dot' />
                            </div>
                        </div>
                    </div>
                </div>

                <div className='leftMobile'>
                    <HambergerMenu size="32" className='menuBtn' onClick={() => setMenu(true)} />
                </div>
                <div className='middleMobile'>
                    <ArchiveBook className="icon" size={30} />
                </div>
                <div className='rightMobile'>
                    <div className='profileContain'>
                        <div className='imgContain'>
                            <div className='img'>
                                <img
                                    src={
                                        !loading ?
                                            window.$url + `/${user.avatar}`
                                            :
                                            require('../../images/avatar.png')}
                                    alt={user.fullName}
                                    onLoad={() => setLoading(false)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* It's <time dateTime={response}>{response}</time> */}

            <div className='middleBottom'>
                {!isFetching ?
                    plans?.map((item, index) => <PlanCard item={item} index={index} />)
                    :
                    <BounceLoader
                        color={"#fff"}
                        loading={true}
                        css={override}
                        size={60}
                    />
                }
            </div>
        </div>
    );
};

export default PackList;