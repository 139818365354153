import axios from "axios";
import { toast } from 'react-toastify';

import { plansFailure, plansStart, plansSuccess } from "./PlansActions";
import { logout } from '../emailContext/EmailActions';

export const getPlans = async (dispatch) => {
    dispatch(plansStart());

    axios.get(window.$url + "/api/developer/plan-list").then((res) => {
        dispatch(plansSuccess(res.data.data.result));
    }).catch((error) => {
        dispatch(plansFailure());

        if (error.response.data.errors.message) {
            toast.error(error.response.data.errors.message);
        } else {
            toast.error('Try again!');
        }
    });
}