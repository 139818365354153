import axios from "axios";
import { toast } from 'react-toastify';

import { payFailure, payStart, paySuccess } from "./PayActions";
import { logout } from '../emailContext/EmailActions';

export const addPay = async (token, dispatch, dispatchEmail, navigate, setIsPayDialog) => {
    dispatch(payStart());

    axios.post(window.$url + "/api/user/pay/payment", {}, {
        headers: {
            token
        }
    }).then((res) => {
        dispatch(paySuccess(res.data.data));
        toast.success(res.data.data.message);

        setIsPayDialog(true);
    }).catch((error) => {
        dispatch(payFailure());

        if (error.response?.data.errors.message) {
            toast.error(error.response.data.errors.message);

            if (error.response.data.errors.message === 'Please login') {
                dispatchEmail(logout());

                navigate('/')
            }
        } else {
            toast.error('Try again!');
        }
    });
}

export const submitPay = async (token, dispatch, dispatchEmail, navigate, setIsPayDialog, txid) => {
    dispatch(payStart());

    axios.get(window.$url + `/api/user/pay/verify/${txid}`, {
        headers: {
            token
        }
    }).then((res) => {
        dispatch(paySuccess());
        toast.success(res.data.data.message);

        getPay(token, dispatch, dispatchEmail, navigate);
        setIsPayDialog(false)
    }).catch((error) => {
        dispatch(payFailure());

        if (error.response?.data.errors.message) {
            toast.error(error.response.data.errors.message);

            if (error.response.data.errors.message === 'Please login') {
                dispatchEmail(logout());

                navigate('/')
            }
        } else {
            toast.error('Try again!');
        }
    });
}

export const getPay = async (token, dispatch, dispatchEmail, navigate) => {
    dispatch(payStart());

    axios.get(window.$url + `/api/user/referral/payments`, {
        headers: {
            token
        }
    }).then((res) => {
        dispatch(paySuccess(res.data.data.payments));
    }).catch((error) => {
        dispatch(payFailure());

        if (error.response?.data.errors.message) {
            toast.error(error.response.data.errors.message);

            if (error.response.data.errors.message === 'Please login') {
                dispatchEmail(logout());

                navigate('/')
            }
        } else {
            toast.error('Try again!');
        }
    });
}