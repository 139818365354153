import React, { useState } from 'react';
import {
    ArrowForward,
} from '@mui/icons-material';
import { DocumentCopy } from 'iconsax-react';
import VerificationInput from 'react-verification-input';

import './googleAuth.scss';

const GoogleAuth = ({ isAuth, setIsAuth, setIsTwoStep, googleAuth, checkGoogleAuth, user, dispatchGoogleAuth, dispatch, navigate }) => {
    const [page, setPage] = useState(1);
    const [authCode, setAuthCode] = useState(null);

    const secondFunc = () => {
        if (page < 2) {
            return 'second';
        } else if (page > 2) {
            return 'second close';
        } else if (page === 2) {
            return 'second open';
        }
    }
    const thirdFunc = () => {
        if (page < 3) {
            return 'third';
        } else if (page > 3) {
            return 'third close';
        } else if (page === 3) {
            return 'third open';
        }
    }

    const copy = async (text) => {
        await navigator.clipboard.writeText(text);
        alert('Code copied');
    }

    return (
        <div className={isAuth ? 'googleAuth' : 'googleAuth close'}>
            <div className='dialogContain'>
                <div className={page === 1 ? 'first' : 'first close'}>
                    <span className='topicTxt'>Two-step code activation</span>
                    <span className='titleTxt'>In three simple steps, your two-step code will be activated</span>

                    <div className='mainAuth'>
                        <div className='count'>
                            1 / 3
                        </div>

                        <span className='titleTxt'>Download the Google authenticator app</span>

                        <img className='googleAuthImg' src={require('../../images/googleAuth.png')} alt='Google Authenticator logo' />

                        <div className='combine'>
                            <a className='googlePlay' rel="noreferrer" href={'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&gl=US'} target="_blank">
                                <img className='googlePlayImg' src={require('../../images/googlePlay.png')} alt='Google Play' />
                            </a>
                            <a className='appleStore' rel="noreferrer" href={'https://apps.apple.com/us/app/google-authenticator/id388497605'} target="_blank">
                                <img className='appleStoreImg' src={require('../../images/appleStore.png')} alt='Apple Store' />
                            </a>
                        </div>
                    </div>
                </div>

                <div className={secondFunc()}>
                    <span className='topicTxt'>Two-step code activation</span>
                    <span className='titleTxt'>In three simple steps, your two-step code will be activated</span>

                    <div className='mainAuth'>
                        <div className='count'>
                            2 / 3
                        </div>

                        <span className='titleTxt'>After downloading the application, scan the following QRcode through the application</span>
                        <span className='titleTxt' style={{ color: '#0088EA' }}>Add manually via code (enter this code in Provided key section)</span>

                        <span className='code' onClick={() => copy(googleAuth?.result.base32)}>
                            <DocumentCopy color="#ffffff" style={{ marginRight: '5px' }} />
                            <span className='codeTxt'>{googleAuth?.result.base32}</span>
                        </span>
                        <img className='googleAuthImg' src={googleAuth?.qrUser} alt='Google Auth' />
                    </div>
                </div>

                <div className={thirdFunc()}>
                    <span className='topicTxt'>Two-step code activation</span>
                    <span className='titleTxt'>In three simple steps, your two-step code will be activated</span>

                    <div className='mainAuth'>
                        <div className='count'>
                            3 / 3
                        </div>

                        <span className='titleTxt'>After entering the information you will be given a 6-digit code, enter the code in the box below</span>

                        <VerificationInput
                            removeDefaultStyles
                            value={authCode}
                            onChange={((value) => setAuthCode(value))}
                            classNames={{
                                container: "varifyContain",
                                character: "varifyChar",
                                characterInactive: "character--inactive",
                                characterSelected: "charSelected",
                            }}
                        />
                    </div>
                </div>

                <button
                    className='next'
                    onClick={() => {
                        if (page < 3) {
                            setPage(page + 1)
                        } else {
                            checkGoogleAuth(user.token, dispatchGoogleAuth, dispatch, navigate, setIsTwoStep, setIsAuth, setPage, authCode, setAuthCode)
                        }
                    }}
                    style={{ marginLeft: '5rem' }}>
                    continue
                    <ArrowForward className='icon' />
                </button>
                <button
                    className='prev'
                    onClick={() => {
                        setIsAuth(false);
                        setPage(1);
                    }}>cancel</button>
            </div>
        </div>
    )
};

export default GoogleAuth;