import React, { createContext, useReducer } from "react";

import GoogleAuthReducer from "./GoogleAuthReducer";

const INITIAL_STATE = {
    googleAuth: null,
    isFetchingGoogleAuth: false,
    error: false,
};

export const GoogleAuthContext = createContext(INITIAL_STATE);

export const GoogleAuthContextProvider = ({ children }) => {
    const [state, dispatchGoogleAuth] = useReducer(GoogleAuthReducer, INITIAL_STATE);

    return (
        <GoogleAuthContext.Provider
            value={{
                googleAuth: state.googleAuth,
                isFetchingGoogleAuth: state.isFetchingGoogleAuth,
                error: state.error,
                dispatchGoogleAuth
            }}
        >
            {children}
        </GoogleAuthContext.Provider>
    )
}