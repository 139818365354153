import axios from "axios";
import { toast } from 'react-toastify';

import { profileFailure, profileStart, profileSuccess } from "./ProfileActions";

import { emailSuccess, logout } from '../emailContext/EmailActions';

export const getProfile = async (token, dispatch, dispatchEmail, navigate) => {
    dispatch(profileStart());

    axios.get(window.$url + "/api/user/profile", {
        headers: {
            token
        }
    }).then((res) => {
        dispatch(profileSuccess(res.data.user));
        
        dispatchEmail(emailSuccess({
            fullName: res.data.user.frist_name,
            mobile: res.data.user.mobile,
            email: res.data.user.email,
            avatar: res.data.user.avatar,
            token: res.data.user.token
        }));
    }).catch((error) => {
        dispatch(profileFailure());

        if (error.response?.data.errors.message) {
            toast.error(error.response.data.errors.message);

            if (error.response.data.errors.message === 'Please login') {
                dispatchEmail(logout());

                navigate('/')
            }
        } else {
            toast.error('Try again!');
        }
    });
}

export const addAvatar = async (avatar, token, dispatch, dispatchEmail, navigate) => {
    dispatch(profileStart());

    const formData = new FormData();

    formData.append("imgName", "img");
    formData.append("image", avatar);

    axios.post(window.$url + "/api/user/profile-image", formData, {
        headers: {
            token
        }
    }).then((res) => {
        getProfile(token, dispatch, dispatchEmail, navigate);
        toast.success(res.data.message);
    }).catch((error) => {
        dispatch(profileFailure());

        if (error.response.data.errors) {
            toast.error(error.response.data.errors.message);

            if (error.response.data.errors.message === 'Please login') {
                dispatchEmail(logout());

                navigate('/')
            }
        } else {
            toast.error('Try again!');
            console.log(error)
        }
    });
}