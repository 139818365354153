import axios from "axios";
import { toast } from 'react-toastify';

import { referralFailure, referralStart, referralSuccess } from "./ReferralActions";

import { logout } from '../emailContext/EmailActions';

export const getReferral = async (token, dispatch, dispatchEmail, navigate) => {
    dispatch(referralStart());

    axios.get(window.$url + "/api/user/referral/user", {
        headers: {
            token
        }
    }).then((res) => {
        dispatch(referralSuccess(res.data.data.result));
    }).catch((error) => {
        dispatch(referralFailure());

        if (error.response?.data.errors.message) {
            toast.error(error.response.data.errors.message);

            if (error.response.data.errors.message === 'Please login') {
                dispatchEmail(logout());

                navigate('/')
            }
        } else {
            toast.error('Try again!');
        }
    });
}