import axios from "axios";
import { toast } from 'react-toastify';

import { userPackFailure, userPackStart, userPackSuccess } from "./UserPackActions";
import { logout } from '../emailContext/EmailActions';

export const getUserPacks = async (dispatch, token, navigate, dispatchEmail) => {
    dispatch(userPackStart());

    axios.get(window.$url + "/api/user/package/package-list", {
        headers: {
            token
        }
    }).then((res) => {
        dispatch(userPackSuccess(res.data.data.result));
    }).catch((error) => {
        dispatch(userPackFailure());

        if (error.response.data.errors.message) {
            toast.error(error.response.data.errors.message);

            if (error.response.data.errors.message === 'You have not purchased any packages') {
                navigate('/plans');
            }

            if (error.response.data.errors.message === 'Please login') {
                dispatchEmail(logout());

                navigate('/')
            }
        } else {
            toast.error('Try again!');
        }
    });
}

export const getUserPackName = async (dispatch, token, navigate, dispatchEmail) => {
    dispatch(userPackStart());

    axios.get(window.$url + "/api/user/package/package-list", {
        headers: {
            token
        }
    }).then((res) => {
        dispatch(userPackSuccess(res.data.data.result));
    }).catch((error) => {
        dispatch(userPackFailure());

        if (error.response.data.errors.message) {
            toast.error(error.response.data.errors.message);

            if (error.response.data.errors.message === 'Please login') {
                dispatchEmail(logout());

                navigate('/')
            }
        } else {
            toast.error('Try again!');
        }
    });
}