const CommissionHisReducer = (state, action) => {
    switch (action.type) {
        case "COMMISSIONHIS_START":
            return {
                commissionHis: null,
                isFetchingCommissionHis: true,
                error: false,
            };
        case "COMMISSIONHIS_SUCCESS":
            return {
                commissionHis: action.payload,
                isFetchingCommissionHis: false,
                error: false,
            };
        case "COMMISSIONHIS_FAILURE":
            return {
                commissionHis: null,
                isFetchingCommissionHis: false,
                error: true,
            };
        default:
            return { ...state };
    }
}

export default CommissionHisReducer;