import React, { useContext } from 'react';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import {
    Copy
} from 'iconsax-react';
import BounceLoader from "react-spinners/BounceLoader";
import { css } from "@emotion/react";
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import './payDialog.scss';

import { EmailContext } from '../../context/emailContext/EmailContext';
import { PayContext } from '../../context/payContext/PayContext';
import { submitPay, getPay } from '../../context/payContext/apiCalls';

const override = css`
  display: block;
`;

const PayDialog = ({ isPayDialog, setIsPayDialog }) => {
    const { user, dispatch } = useContext(EmailContext);
    const { pay, dispatchPay, isFetchingPay } = useContext(PayContext);

    const navigate = useNavigate();

    const copy = async (text) => {
        await navigator.clipboard.writeText(text);
        toast.success('Text copied');
    }

    function convertStoMs(seconds) {
        let minutes = Math.floor(seconds / 60);
        let extraSeconds = seconds % 60;
        minutes = minutes < 10 ? "0" + minutes : minutes;
        extraSeconds = extraSeconds < 10 ? "0" + extraSeconds : extraSeconds;
        return (minutes + " : " + extraSeconds);
    }

    return (
        <div className={isPayDialog ? 'payDialog' : 'payDialog close'}>
            <div className='dialogContain'>
                <div className='headerContain'>
                    <div className='headerTitle'>
                        <span className='title'>Send Payment</span>
                        <span className='content'>Open your crypto wallet and scan the QR code, or copy the TRC20 - Tether address below to make a payment.</span>
                    </div>

                    <div className='haderTimmer'>
                        <CountdownCircleTimer
                            isPlaying
                            duration={1800}
                            colors={['#13CC68']}
                            size={90}
                            strokeWidth={3}
                        >
                            {({ remainingTime }) => convertStoMs(remainingTime)}
                        </CountdownCircleTimer>
                    </div>
                </div>

                <div className='seperator' />

                {pay !== null &&
                    <img className='qr' src={pay?.myQrCode} alt='pay' />
                }

                <div className='seperator' />

                <div className='footerFirst' style={{ color: '#13CC68' }} onClick={() => copy(pay?.result?.address)}>
                    <img className='tether' src={require('../../images/tether2.png')} alt='tether' />

                    <span>Tether - TRC20 Address</span>
                </div>

                <div className='footerFirst' onClick={() => copy(pay?.result?.address)}>

                    <span className='addressTxt'>{pay?.result?.address}</span>

                    <span className='copyTxt'>copy</span>
                    <Copy className="icon" color='#13CC68' size={30} />
                </div>

                <div className='seperator' />

                <div className='footerSecond' onClick={() => copy(pay?.result?.amount)}>
                    <span className='amountTitle'>Total amount</span>

                    <div className='footerFirst' onClick={() => copy(pay?.result?.address)}>
                        <span className='amountTxt'>{pay?.result?.amount}$</span>

                        <span className='copyTxt'>copy</span>
                        <Copy className="icon" color='#13CC68' size={30} />
                    </div>
                </div>

                <div className='seperator' />

                <div className='btnContain'>
                    <button className='btn' onClick={() => {
                        getPay(user.token, dispatchPay, dispatch, navigate)
                        setIsPayDialog(false)
                    }}>Cancel</button>
                    <button className='btn' disabled={isFetchingPay} onClick={() => submitPay(user.token, dispatchPay, dispatch, navigate, setIsPayDialog, pay?.result?.txn_id)}>
                        {!isFetchingPay ?
                            'Paid'
                            :
                            <BounceLoader color={"#fff"} loading={true} css={override} size={15} />
                        }
                    </button>
                </div>
            </div>
        </div>
    );
};

export default PayDialog;