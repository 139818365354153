import React, { createContext, useReducer } from "react";

import ResetPassReducer from "./ResetPassReducer";

const INITIAL_STATE = {
    resetPass: null,
    isFetchingResetPass: false,
    error: false,
};

export const ResetPassContext = createContext(INITIAL_STATE);

export const ResetPassContextProvider = ({ children }) => {
    const [state, dispatchResetPass] = useReducer(ResetPassReducer, INITIAL_STATE); 

    return (
        <ResetPassContext.Provider
            value={{
                resetPass: state.resetPass,
                isFetchingResetPass: state.isFetchingResetPass,
                error: state.error,
                dispatchResetPass
            }}
        >
            {children}
        </ResetPassContext.Provider>
    )
}