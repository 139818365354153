import React, { useState } from 'react';

import './newsCard.scss';

const NewsCard = ({ item, index }) => {
    const [loading, setLoading] = useState(true);

    return (
        <div key={index} className='newsCard'>
            <div className='imgNewsContain'>
                <img
                    className='imgNews'
                    src={!loading ? `https://api.fxtrader.cm:3030${item.image}` : require('../../images/defaultImg.png')}
                    alt={item.title}
                    onLoad={() => setLoading(false)}
                />
            </div>
            <span className='newsTopic'>Fx News</span>
            <span className='newsBold'>Samson Mow Reveals Which Countries May NEVER Adopt BTC as Legal Tender… and Who Might Be Next</span>
            <span className='news'>Mow also said that the real "litmus test" is getting a larger country that isn't suffering economic hardship to embrace Bitcoin as legal tender.</span>

            <div className='newsFooter'>
                <span className='newsLeft'>By Ali Reazai</span>
                <span className='newsRight'>4h ago</span>
            </div>
        </div>
    );
};

export default NewsCard;