import React, { useState, useContext } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { ToastContainer } from 'react-toastify';

import './app.scss';
import 'react-toastify/dist/ReactToastify.css';
import Home from './pages/Home/Home';
import Login from './pages/login/Login';
import Register from './pages/register/Register';

import Sidebar from './component/sidebar/Sidebar';
import Dashboard from './pages/dashboard/Dashboard';
import Roboman from './pages/roboman/Roboman';
import Verify from './pages/verify/Verify';
import SecurityTab from './pages/security/SecurityTab';
import Referral from './pages/referral/Referral';
import Shopping from './pages/shopping/Shopping';
import Setting from './pages/setting/Setting';
import Support from './pages/support/Support';
import PackList from './pages/packList/PackList';
import NotFound from './pages/notFound/NotFound';

import { EmailContext } from './context/emailContext/EmailContext';

function App() {
  const [menu, setMenu] = useState(false);

  const { user } = useContext(EmailContext);
  
  window.$url = 'https://api.fxtraderbot.ir';

  return (
    <BrowserRouter>
      <div className='app'>
        <Routes>
          <Route
            path="/"
            element={<Navigate to="/home" />}
          />

          <Route path='*' element={<NotFound />} />

          <Route path="/home" element={
            <>
              <Home />
            </>
          } />
          <Route path="/login" element={
            <Login />
          } />
          <Route path="/register" element={
            <Register />
          } />


          {user && (<>
            <Route path="/dashboard" element={
              <>
                <Sidebar menu={menu} setMenu={setMenu} />
                <Dashboard menu={menu} setMenu={setMenu} />
              </>
            } />
            <Route path="/roboman" element={
              <>
                <Sidebar menu={menu} setMenu={setMenu} />
                <Roboman menu={menu} setMenu={setMenu} />
              </>
            } />
            <Route path="/verify" element={
              <>
                <Sidebar menu={menu} setMenu={setMenu} />
                <Verify menu={menu} setMenu={setMenu} />
              </>
            } />
            <Route path="/security" element={
              <>
                <Sidebar menu={menu} setMenu={setMenu} />
                <SecurityTab menu={menu} setMenu={setMenu} />
              </>
            } />
            <Route path="/referral" element={
              <>
                <Sidebar menu={menu} setMenu={setMenu} />
                <Referral menu={menu} setMenu={setMenu} />
              </>
            } />
            <Route path="/shopping" element={
              <>
                <Sidebar menu={menu} setMenu={setMenu} />
                <Shopping menu={menu} setMenu={setMenu} />
              </>
            } />
            <Route path="/setting" element={
              <>
                <Sidebar menu={menu} setMenu={setMenu} />
                <Setting menu={menu} setMenu={setMenu} />
              </>
            } />
            <Route path="/support" element={
              <>
                <Sidebar menu={menu} setMenu={setMenu} />
                <Support menu={menu} setMenu={setMenu} />
              </>
            } />
            <Route path="/plans" element={
              <>
                <Sidebar menu={menu} setMenu={setMenu} />
                <PackList menu={menu} setMenu={setMenu} />
              </>
            } />
          </>)}
        </Routes>
      </div>
      <ToastContainer
        position='top-right'
        autoClose={5000}
        hideProgressBar={false}
        closeOnClick={true}
        pauseOnHover={true}
        draggable={true}
      />
    </BrowserRouter>
  );
}

export default App;
