import React, { useContext } from 'react';
import { Link } from "react-router-dom";

import './menu.scss';
import { EmailContext } from '../../context/emailContext/EmailContext';
import { logoutUser } from '../../context/emailContext/apiCalls';

const Menu = ({ handleScroll, open, setOpen }) => {
  const { user, dispatch } = useContext(EmailContext);

  return (
    <div className={open ? 'menu' : 'menu close'}>
      <div className='seperator' />

      <div className='main'>
        <div className='item' onClick={() => { handleScroll('first'); setOpen(false) }}>
          Plans
        </div>
        <div className='seperator' />
        <div className='item' onClick={() => { handleScroll('second'); setOpen(false) }}>
          Application
        </div>
        <div className='seperator' />
        <div className='item' onClick={() => { handleScroll('third'); setOpen(false) }}>
          Blog
        </div>
        <div className='seperator' />
        <div className='item' onClick={() => { handleScroll('forth'); setOpen(false) }}>
          Contact
        </div>
        <div className='seperator' />
        <div className='item'>
          About
        </div>
        <div className='seperator' />

        {user ?
          <div className='item' onClick={() => { logoutUser(dispatch) }}>
            Logout
          </div>
          :
          <div className='authItem'>
            <div className='innerItem'>
              <Link to="/login" className="link">
                Login
              </Link>
            </div>
            <div className='innerSeperate' />
            <div className='innerItem'>
              <Link to="/register" className="link">
                Register
              </Link>
            </div>
          </div>
        }
      </div>
    </div>
  )
};

export default Menu;