import React, { useState, useEffect } from 'react';

import './policyDialog.scss';
import policyData from './policyData.json';

const PolicyDialog = ({ openPolicy, setOpenPolicy }) => {
    const [data, setData] = useState([]);

    useEffect(() => {
        if (policyData) {
            setData(policyData.data);
        }
    }, []);

    return (
        <div className={openPolicy ? 'policyDialog' : 'policyDialog close'}>
            <div className='dialogContain'>
                <div className='fxContain'>
                    <img className='fxImg' src={require('../../images/logo.png')} alt='fx logo' />
                </div>

                <div className='contentContain'>
                    <span className='topic'>Privacy Policy</span>

                    <div className='main'>
                        {
                            data && data.map((item, index) =>
                                <span className='lineContain' key={index}>
                                    {item.num}
                                    <span className='line'>{item.line}</span>
                                </span>
                            )
                        }
                    </div>
                    <button className='closeBtn' onClick={() => setOpenPolicy(false)}>Close</button>
                </div>

                {/* <span className='topic'>Withdraw</span>
                <Danger className='icon' />

                <div className='mainContain'>
                    <div className='inputTitleContain'>
                        <EmptyWallet className='wallet' size={20} />
                        <span className='inputTitle'>TRC20 USDT wallet address</span>
                    </div>

                    <div className='inputContain'>
                        <input className='input' placeholder='Enter your wallet address' />
                    </div>

                    <div className='checkContain' onClick={() => setChecked(!checked)}>
                        <Checkbox
                            checked={checked}
                            sx={{
                                padding: 0,
                                color: '#13CC68',
                                '&.Mui-checked': {
                                    color: '#13CC68',
                                },
                            }}
                        />
                        <span className='checkLabel'>I'm sure I entered the correct address</span>
                    </div>
                </div>

                <button className='btn'>
                    Confirm
                    <ArrowCircleRight className='iconBtn' variant='Bold' />
                </button> */}
            </div>
        </div>
    )
};

export default PolicyDialog;