import React, { useState, useContext, useEffect } from 'react';
import {
    Sms,
    Key,
    Eye,
    EyeSlash,
    ArrowCircleRight
} from 'iconsax-react';
import { Link, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import BounceLoader from "react-spinners/BounceLoader";
import { css } from "@emotion/react";

import './login.scss';
import EmailDialog from '../../component/emailDialog/EmailDialog';
import { EmailContext } from '../../context/emailContext/EmailContext';
import { loginUser } from '../../context/emailContext/apiCalls';

const override = css`
  display: block;
`;

const Login = () => {
    const [showPass, setShowPass] = useState(false);
    const [email, setEmail] = useState(false);
    const [userEmail, setUserEmail] = useState('');
    const [password, setPassword] = useState('');

    const { user, isFetching, dispatch } = useContext(EmailContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (user !== null) {
            navigate('/dashboard');
        }
    }, [user]);

    const handleLogin = () => {
        if (userEmail !== '' && password !== '') {
            loginUser(userEmail, password, dispatch);
        } else {
            toast.error("Please complete all fields!");
        }
    }

    return (
        <div className='login'>
            <div className='loginContain'>
                <div className='loginLeft'>
                    <span className='mainTitle'>Welcome back,</span>
                    <div className='loginTitleContain'>
                        To
                        <img className='fxLogo' src={require('../../images/logo.png')} alt={'Fx logo'} />
                        Trader
                    </div>

                    <div className='inputContain'>
                        <Sms color='#fff' className='loginIcon' />
                        <input className='loginInput' placeholder='Enter yout email' type={'email'} onChange={(e) => setUserEmail(e.target.value)} />
                    </div>
                    
                    <div className='inputContain'>
                        <Key color='#fff' className='loginIcon' />
                        <input className='loginInput' placeholder='Enter yout password' type={showPass ? 'text' : 'password'} onChange={(e) => setPassword(e.target.value)} />
                        <div className='showPass' onClick={() => setShowPass(!showPass)}>
                            {
                                !showPass
                                    ?
                                    <EyeSlash color='#fff' className='loginIcon' />
                                    :
                                    <Eye color='#fff' className='loginIcon' />
                            }
                        </div>
                    </div>

                    <div className='forgetPassContain'>
                        <span className='forgetPass' onClick={() => setEmail(true)}>Forgot Password?</span>
                    </div>

                    <button className='btn' disabled={isFetching} onClick={handleLogin}>
                        {/* <Link to="/dashboard" className="link"> */}
                        {!isFetching ?
                            <div className='innerBtn'>
                                Login
                                <ArrowCircleRight color='#fff' size={20} variant='Bold' style={{ marginLeft: '5px' }} />
                            </div>
                            :
                            <BounceLoader color={"#fff"} loading={true} css={override} size={15} />
                        }
                        {/* </Link> */}
                    </button>

                    <div>
                        <span className='noAccount'>
                            <Link to="/register" className="link">
                                I don't have an account!
                            </Link>
                        </span>
                    </div>
                </div>
                <div className='loginRight'>
                    <img className='loginCover' src={require('../../images/loginCover.png')} alt='Fx Login Cover' />
                </div>
            </div>

            {email ?
                <EmailDialog setEmail={setEmail} />
                :
                null
            }
        </div>
    )
};

export default Login;