import React, { useState, useEffect, useRef } from 'react';
import {
    CpuSetting,
    Add
} from 'iconsax-react';

import './shoppingItem.scss';

const ShoppingItem = ({ item, index, handleRemove }) => {
    const [isOpen, setIsOpen] = useState(true);
    const [display, setDisplay] = useState('flex');

    useEffect(() => {
        if (isOpen === true) {
            setDisplay('flex');
        } else if (isOpen === false) {
            setTimeout(function () {
                setDisplay('none');
            }, 700);
        }
    }, [isOpen]);

    const fetch = useRef(true);

    useEffect(() => {
        if (fetch.current) {
            fetch.current = false;

        }
    }, []);

    const handleColor = (item) => {
        switch (item.type) {
            case '0':
                return ('#767676');
            case '1':
                return ('#4D77E2');
            case '2':
                return ('#D7C100');
            default:
                return;
        }
    }
    const handleTitle = (item) => {
        switch (item.type) {
            case '0':
                return ('Basic panel');
            case '1':
                return ('Standard panel');
            case '2':
                return ('Premium panel');
            default:
                return;
        }
    }

    return (
        <>
            <div className={isOpen ? 'shoppingItem' : 'shoppingItem close'} style={{ display: display }} key={index}>
                <div className='productItem'>
                    <CpuSetting className='itemIcon' color={handleColor(item)} size={40} />

                    <div className='itemTitleContain'>
                        <span className='itemTitle1'>
                            {handleTitle(item)}
                        </span>
                        <span className='itemTitle2'>
                            Trading Robot
                        </span>
                    </div>
                </div>
                <span className='quantityItem'>
                    {item.basketCount}
                </span>
                <span className='priceItem'>
                    ${parseInt(item.finalPrice)}
                </span>
                <span className='deleteItem'>
                    <Add className='closeIcon' onClick={() => {
                        handleRemove(item._id, isOpen, setIsOpen);
                    }} />
                </span>
            </div>

            <div className='seperator' style={{ display: display }} />
        </>
    );
};

export default ShoppingItem;