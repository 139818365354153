import React, { createContext, useReducer } from "react";

import CommissionHisReducer from "./CommissionHisReducer";

const INITIAL_STATE = {
    commissionHis: null,
    isFetchingCommissionHis: false,
    error: false,
};

export const CommissionHisContext = createContext(INITIAL_STATE);

export const CommissionHisContextProvider = ({ children }) => {
    const [state, dispatchCommissionHis] = useReducer(CommissionHisReducer, INITIAL_STATE); 

    return (
        <CommissionHisContext.Provider
            value={{
                commissionHis: state.commissionHis,
                isFetchingCommissionHis: state.isFetchingCommissionHis,
                error: state.error,
                dispatchCommissionHis
            }}
        >
            {children}
        </CommissionHisContext.Provider>
    )
}