const BasketReducer = (state, action) => {
    switch (action.type) {
        case "BASKET_START":
            return {
                basket: null,
                isFetchingBasket: true,
                error: false,
            };
        case "BASKET_SUCCESS":
            return {
                basket: action.payload,
                isFetchingBasket: false,
                error: false,
            };
        case "BASKET_FAILURE":
            return {
                basket: null,
                isFetchingBasket: false,
                error: true,
            };
        default:
            return { ...state };
    }
}

export default BasketReducer;