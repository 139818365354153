import React, { useEffect, useState, useContext, useRef } from 'react';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import {
    DataGrid,
    gridPageCountSelector,
    gridPageSelector,
    useGridApiContext,
    useGridSelector,
} from '@mui/x-data-grid';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import {
    Shield,
    Notification,
    Key,
    HambergerMenu
} from 'iconsax-react';
import { useNavigate } from 'react-router-dom';
import BounceLoader from "react-spinners/BounceLoader";
import { css } from "@emotion/react";

import './securityTab.scss';
import fakeData from './data.json';
import GoogleAuth from '../../component/googleAuth/GoogleAuth';
import LoaderPage from '../../component/loaderPage/LoaderPage';
import ResetPassDialog from '../../component/resetPassDialog/ResetPassDialog';

import { EmailContext } from '../../context/emailContext/EmailContext';
import { ProfileContext } from '../../context/profileContext/ProfileContext';
import { getProfile } from '../../context/profileContext/apiCalls';
import { GoogleAuthContext } from '../../context/googleAuthContext/GoogleAuthContext';
import { createGoogleAuth, disableGoogleAuth, checkGoogleAuth } from '../../context/googleAuthContext/apiCalls';
import { ResetPassContext } from '../../context/resetPassContext/ResetPassContext';
import { sendResetEmail } from '../../context/resetPassContext/apiCalls';

const override = css`
  display: block;
`;

const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 45,
    height: 22,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(25px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: '#fff',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
            '& .MuiSwitch-thumb': {
                boxSizing: 'border-box',
                width: 18,
                height: 18,
                backgroundColor: '#13CC68'
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 18,
        height: 18,
        backgroundColor: 'red'
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));

const SecurityTab = ({ menu, setMenu }) => {
    const { user, dispatch, isFetching } = useContext(EmailContext);
    const { profile, dispatchProfile, isFetchingProfile } = useContext(ProfileContext);
    const { googleAuth, dispatchGoogleAuth, isFetchingGoogleAuth } = useContext(GoogleAuthContext);
    const { dispatchResetPass, isFetchingResetPass } = useContext(ResetPassContext);

    const [email, setEmail] = useState(false);

    const [loading, setLoading] = useState(true);
    const data = fakeData.data;
    const [isAuth, setIsAuth] = useState(false);
    const [isTwoStep, setIsTwoStep] = useState(false);

    const navigate = useNavigate();

    const fetch = useRef(true);
    useEffect(() => {
        if (fetch.current) {
            fetch.current = false;

            getProfile(user.token, dispatchProfile, dispatch, navigate);
        }
    }, []);

    useEffect(() => {
        if (profile?.statustwoFactir !== undefined) {
            setIsTwoStep(profile.statustwoFactir);
        }
    }, [profile]);

    const columns = [
        {
            field: "date",
            headerName: "Date",
            width: 90,
            headerClassName: 'headerData',
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                return (
                    <div className="listItem">
                        {params.row.date}
                    </div>
                );
            },
        },
        {
            field: "ip",
            headerName: "IP",
            width: 150,
            headerClassName: 'headerData',
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                return (
                    <div className="listItem">
                        {params.row.ip}
                    </div>
                );
            },
        },
        {
            field: "browserId",
            headerName: "Browser ID",
            width: 600,
            headerClassName: 'headerData',
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                return (
                    <div className='listItem'>
                        <span className='span'>{params.row.browserId}</span>
                    </div>
                );
            },
        },
        {
            field: "status",
            headerName: "Status",
            width: 70,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                return (
                    <div className="listItem">
                        {params.row.status}
                    </div>
                );
            },
        },
    ];

    function CustomPagination() {
        const apiRef = useGridApiContext();
        const page = useGridSelector(apiRef, gridPageSelector);
        const pageCount = useGridSelector(apiRef, gridPageCountSelector);

        return (
            <Pagination
                color="primary"
                variant="outlined"
                shape="rounded"
                page={page + 1}
                count={pageCount}
                style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    width: '100%',
                    direction: 'ltr',
                    color: '#fff'
                }}
                // @ts-expect-error
                renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
                onChange={(event, value) => apiRef.current.setPage(value - 1)}
            />
        );
    }

    return (
        <div className='securityTab'>
            <div className={menu ? 'mask' : 'mask close'} />

            <div className='topFirst'>
                <div className='left'>
                    <Shield className="icon" size={60} />
                    <div className='titleContain'>
                        <span className='mainTitle'>Security</span>
                    </div>
                </div>
                <div className='right'>
                    <div className='rightContent'>
                        <button className='buyBtn'>Buy Robot</button>
                        <div className='profileContain'>
                            <div className='imgContain'>
                                <div className='img'>
                                    <img
                                        src={
                                            !loading ?
                                                window.$url + `/${user.avatar}`
                                                :
                                                require('../../images/avatar.png')}
                                        alt={user.fullName}
                                        onLoad={() => setLoading(false)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='infoContain'>
                            <span className='name'>
                                {user.fullName}
                            </span>
                            <span className='email'>
                                {user.email}
                            </span>
                        </div>
                        <div className='notifContain'>
                            <div className='combine'>
                                <Notification className='icon' size={20} />
                                <div className='dot' />
                            </div>
                        </div>
                    </div>
                </div>

                <div className='leftMobile'>
                    <HambergerMenu size="32" className='menuBtn' onClick={() => setMenu(true)} />
                </div>
                <div className='middleMobile'>
                    <Key className="icon" size={30} />
                </div>
                <div className='rightMobile'>
                    <div className='profileContain'>
                        <div className='imgContain'>
                            <div className='img'>
                                <img
                                    src={
                                        !loading ?
                                            window.$url + `/${user.avatar}`
                                            :
                                            require('../../images/avatar.png')}
                                    alt={user.fullName}
                                    onLoad={() => setLoading(false)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='top'>
                <div className='card'>
                    <div className='left'>
                        <img className='img' src={require('../../images/googleAuth.png')} alt='googleAuth' />
                    </div>

                    <div className='center'>
                        Two step verification
                    </div>

                    <div className='right'>
                        <FormControlLabel
                            control={
                                <IOSSwitch
                                    sx={{ m: 1 }}
                                    checked={isTwoStep}
                                    onChange={(e) => {
                                        setIsAuth(e.target.checked)
                                        if (e.target.checked) {
                                            createGoogleAuth(user.token, dispatchGoogleAuth, dispatch, navigate);
                                        } else if (!e.target.checked) {
                                            setIsTwoStep(e.target.checked)
                                            disableGoogleAuth(user.token, dispatchGoogleAuth, dispatch, navigate);
                                        }
                                    }} />
                            }
                        />
                    </div>

                    <div className={isTwoStep ? 'twoStepContain' : 'twoStepContain close'}>
                        Two step is activated
                    </div>
                </div>

                <div className='card'>
                    <div className='left'>
                        <Key size={60} color='#fff' />
                    </div>

                    <div className='center'>
                        Account password
                    </div>

                    <button className='right' disabled={isFetchingResetPass} onClick={() => sendResetEmail(user?.token, setEmail, user?.email, dispatchResetPass, dispatch, navigate)}>
                        <span className={!isFetchingResetPass ? 'txtChange' : 'txtChange close'}>Change</span>

                        <div className={isFetchingResetPass ? 'loadContain' : 'loadContain close'}>
                            <BounceLoader color={"#fff"} loading={true} css={override} size={50} />
                        </div>
                    </button>
                </div>
            </div>

            <span className='historyTitle'>Your account login history</span>

            <div className='gridContain'>
                <DataGrid
                    pageSize={10}
                    rows={data}
                    disableSelectionOnClick
                    columns={columns}
                    getRowId={r => r.id}
                    getRowHeight={() => 'auto'}
                    getEstimatedRowHeight={() => 200}
                    style={{
                        color: '#fff',
                        height: '500px',
                        backgroundColor: 'transparent',
                        overflowX: 'auto',
                        margin: '20px',
                    }}
                    sx={{
                        border: 'none',
                        '&>.MuiDataGrid-main': {
                            '&>.MuiDataGrid-columnHeaders': {
                                borderColor: '#414141',
                            },
                            '&>.MuiDataGrid-columnFooter': {
                                borderColor: '#414141',
                            },
                            '& div div div div >.MuiDataGrid-cell': {
                                borderColor: '#414141'
                            },
                        },
                        '.MuiDataGrid-columnSeparator': {
                            display: 'none',
                        },
                        '&.MuiDataGrid-root': {
                            border: 'none',
                        },
                        "& .MuiPaginationItem-root": {
                            color: "#fff",
                        },
                    }}
                    components={{
                        NoRowsOverlay: () => (
                            <div className="noRows">
                                <span>
                                    No history
                                </span>
                            </div>
                        ),
                        Pagination: CustomPagination,
                    }}
                />
            </div>

            <GoogleAuth
                isAuth={isAuth}
                setIsAuth={setIsAuth}
                setIsTwoStep={setIsTwoStep}
                googleAuth={googleAuth}
                checkGoogleAuth={checkGoogleAuth}
                user={user}
                dispatchGoogleAuth={dispatchGoogleAuth}
                dispatch={dispatch}
                navigate={navigate}
            />

            <LoaderPage isFetching={isFetchingProfile} />
            <LoaderPage isFetching={isFetchingGoogleAuth} />

            {email && <ResetPassDialog setEmail={setEmail} />}
        </div>
    )
};

export default SecurityTab;