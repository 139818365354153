const ProfileReducer = (state, action) => {
    switch (action.type) {
        case "PROFILE_START":
            return {
                profile: null,
                isFetchingProfile: true,
                error: false,
            };
        case "PROFILE_SUCCESS":
            return {
                profile: action.payload,
                isFetchingProfile: false,
                error: false,
            };
        case "PROFILE_FAILURE":
            return {
                profile: null,
                isFetchingProfile: false,
                error: true,
            };
        default:
            return { ...state };
    }
}

export default ProfileReducer;