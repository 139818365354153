import React, { useState, useContext } from 'react';
import {
    MessageQuestion,
    Notification,
    HambergerMenu,
    Messages2
} from 'iconsax-react';

import './support.scss';
import TicketHistory from '../../component/supportComponent/ticketHistory/TicketHistory';
import TicketWrite from '../../component/supportComponent/ticketWrite/TicketWrite';
import TicketConversation from '../../component/supportComponent/ticketConversation/TicketConversation';
import ChatDialog from '../../component/chatDialog/ChatDialog';

import { EmailContext } from '../../context/emailContext/EmailContext';

const Support = ({ menu, setMenu }) => {
    const [loading, setLoading] = useState(true);
    const [pageType, setPageType] = useState('history');
    const [conversationData, setConversationData] = useState(null);
    const [chat, setChat] = useState(false);

    const { user } = useContext(EmailContext);

    return (
        <div className='support'>
            <div className={menu ? 'mask' : 'mask close'} />

            <div className='topFirst'>
                <div className='left'>
                    <MessageQuestion className="icon" size={60} />
                    <div className='titleContain'>
                        <span className='mainTitle'>Support</span>
                    </div>
                </div>
                <div className='right'>
                    <div className='rightContent'>
                        <button className='buyBtn'>Buy Robot</button>
                        <div className='profileContain'>
                            <div className='imgContain'>
                                <div className='img'>
                                    <img
                                        src={
                                            !loading ?
                                                window.$url + `/${user.avatar}`
                                                :
                                                require('../../images/avatar.png')}
                                        alt={user.fullName}
                                        onLoad={() => setLoading(false)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='infoContain'>
                            <span className='name'>
                                {user.fullName}
                            </span>
                            <span className='email'>
                                {user.email}
                            </span>
                        </div>
                        <div className='notifContain'>
                            <div className='combine'>
                                <Notification className='icon' size={20} />
                                <div className='dot' />
                            </div>
                        </div>
                    </div>
                </div>

                <div className='leftMobile'>
                    <HambergerMenu size="32" className='menuBtn' onClick={() => setMenu(true)} />
                </div>
                <div className='middleMobile'>
                    <MessageQuestion className="icon" size={30} />
                </div>
                <div className='rightMobile'>
                    <div className='profileContain'>
                        <div className='imgContain'>
                            <div className='img'>
                                <img
                                    src={
                                        !loading ?
                                            window.$url + `/${user.avatar}`
                                            :
                                            require('../../images/avatar.png')}
                                    alt={user.fullName}
                                    onLoad={() => setLoading(false)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={pageType === 'history' ? 'supportContain' : 'supportContain left'}>
                <TicketHistory setPageType={setPageType} setConversationData={setConversationData} />
            </div>

            <div className={pageType === 'write' ? 'supportContain' : 'supportContain left'}>
                <TicketWrite setPageType={setPageType} setConversationData={setConversationData} />
            </div>

            <div className={pageType === 'conversation' ? 'supportContain' : 'supportContain left'}>
                <TicketConversation setPageType={setPageType} conversationData={conversationData} />
            </div>

            {/* <div className='message' onClick={() => setChat(true)}>
                <Messages2 size={60} color={'#fff'} />
            </div> */}

            {/* <ChatDialog chat={chat} setChat={setChat} /> */}
        </div>
    )
};

export default Support;