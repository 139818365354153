import React, { useEffect, useState } from 'react';
import { Card, CardActionArea, Grid, } from '@mui/material';
import { PowerSettingsNewOutlined } from '@mui/icons-material';
import {
    Setting2,
    Gallery,
    Android
} from 'iconsax-react'

import './roboCard.scss';

const RoboCard = ({ item, key }) => {
    const [setting, setSetting] = useState(false);
    const [start, setStart] = useState('');
    const [end, setEnd] = useState('');

    useEffect(() => {
        let first = new Intl.DateTimeFormat('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(item.startat);
        let second = new Intl.DateTimeFormat('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(item.endat);
        
        setStart(first)
        setEnd(second)
    }, [])

    return (
        <Grid item xs={12} md={4} lg={4} key={key}>
            <Card sx={{ maxWidth: 400 }} style={{ background: 'linear-gradient(90deg, rgba(26, 28, 32, 1) 0%, rgba(18, 17, 17, 1) 100%)', borderRadius: '1.375rem', position: 'relative', overflow: 'visible', margin: '70px 20px' }}>
                <div className='topCard'>
                    <div className='roboImgContain'>
                        <Android className='icon' size={30} />
                    </div>
                </div>
                <CardActionArea style={{ width: '100%', height: '100%', padding: '2rem', cursor: 'auto' }}>
                    <div className='roboCard'>
                        <span className='nameTxt'>{item.name}</span>
                        <div className='coinLabel'>
                            <span className='label'>Robot status</span>
                            <span className='value'>{item.botstatus ?
                                <>
                                    <img className='dot' alt='dot' src={require('../../images/dot.png')} />
                                    Active
                                </>
                                :
                                <div style={{ color: 'red' }}>Deactive</div>
                            }</span>
                        </div>
                        <div className='coinLabel'>
                            <span className='label'>Overall profit</span>
                            <span className='value'>{item.profit}</span>
                        </div>
                        <div className='coinLabel'>
                            <span className='label'>Trades</span>
                            <span className='value'>{item.trades}</span>
                        </div>
                        <div className='coinLabel'>
                            <span className='label'>Start date</span>
                            <span className='value' style={{ color: '#B1B1B1' }}>{start}</span>
                        </div>
                        <div className='coinLabel'>
                            <span className='label'>End date</span>
                            <span className='value' style={{ color: '#B1B1B1' }}>{end}</span>
                        </div>

                        <div className='bottomContain'>
                            <div className='bottom'>
                                <Setting2 className='iconL' size={30} onClick={() => setSetting(true)} />
                            </div>
                            <div className='bottom'>
                                <PowerSettingsNewOutlined className='iconR' />
                            </div>
                        </div>

                        <div className={setting ? 'settingContain' : 'settingContain close'}>
                            <div className='main'>
                                <span className='settingTopic'>Robot Settings</span>

                                <span className='settingTxt'>Login ID</span>
                                <input className='input' type={'number'} />

                                <span className='settingTxt'>Max Position</span>
                                <input className='input' type={'number'} />

                                <span className='settingTxt'>Lot Size</span>
                                <input className='input' type={'number'} />
                            </div>

                            <div className='bottom'>
                                <span className='uploadTxt'>Upload Avatar</span>
                                <span className='saveTxt' onClick={() => setSetting(false)}>Save Settings</span>
                            </div>
                        </div>
                    </div>
                </CardActionArea>
            </Card>
        </Grid>
    )
};

export default RoboCard;