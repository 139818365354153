import axios from "axios";
import { toast } from 'react-toastify';

import { ticketFailure, ticketStart, ticketSuccess } from "./TicketActions";

import { logout } from '../emailContext/EmailActions';

export const addTicket = async (data, token, dispatch, dispatchEmail, navigate, attach) => {
    dispatch(ticketStart());

    axios.post(window.$url + "/api/user/ticket/create-ticket", {
        email: data.email,
        topic: data.topic,
        department: data.department,
        releventRobot: data.releventRobot,
        statustick: data.statustick,
        importTick: data.importTick,
        text: data.text
    }, {
        headers: {
            token
        }
    }).then((res) => {
        if (attach !== null) {
            toast.success(res.data.data.message)
            addAttach(attach, token, dispatch, dispatchEmail, navigate, res.data.data.result._id, res.data.data.result);
        } else {
            toast.success(res.data.data.message)
            dispatch(ticketSuccess(res.data.data.result));
            window.location.reload();
        }
    }).catch((error) => {
        dispatch(ticketFailure());

        if (error.response?.data.errors.message) {
            toast.error(error.response.data.errors.message);

            if (error.response.data.errors.message === 'Please login') {
                dispatchEmail(logout());

                navigate('/')
            }
        } else {
            toast.error('Try again!');
        }
    });
}

export const answerTicket = async (data, token, dispatch, dispatchEmail, navigate, attach, parentID) => {
    dispatch(ticketStart());

    axios.post(window.$url + "/api/user/ticket/add-answer", {
        email: data.email,
        name: data.name,
        parentID,
        text: data.text
    }, {
        headers: {
            token
        }
    }).then((res) => {
        if (attach !== null) {
            toast.success(res.data.data.message)
            addAttach(attach, token, dispatch, dispatchEmail, navigate, res.data.data.result._id, res.data.data.result);
        } else {
            toast.success(res.data.data.message)
            dispatch(ticketSuccess(res.data.data.result));
            window.location.reload();
        }
    }).catch((error) => {
        dispatch(ticketFailure());

        if (error.response?.data.errors.message) {
            toast.error(error.response.data.errors.message);

            if (error.response.data.errors.message === 'Please login') {
                dispatchEmail(logout());

                navigate('/')
            }
        } else {
            toast.error('Try again!');
        }
    });
}

export const addAttach = async (attach, token, dispatch, dispatchEmail, navigate, id, data) => {
    const formData = new FormData();

    formData.append("imgName", "img");
    formData.append("ticket", attach);

    axios.patch(window.$url + `/user/ticket/edit-imagetiket/${id}`, formData, {
        headers: {
            token
        }
    }).then((res) => {
        dispatch(ticketSuccess(data));
        toast.success(res.data.data.message);
        window.location.reload();
    }).catch((error) => {
        dispatch(ticketFailure());

        if (error.response.data.errors) {
            toast.error(error.response.data.errors.message);

            if (error.response.data.errors.message === 'Please login') {
                dispatchEmail(logout());

                navigate('/')
            }
        } else {
            toast.error('Try again!');
            console.log(error)
        }
    });
}

export const getTickets = async (token, dispatch, dispatchEmail, navigate) => {
    dispatch(ticketStart());

    axios.get(window.$url + "/api/user/ticket/ticket-list", {
        headers: {
            token
        }
    }).then((res) => {
        dispatch(ticketSuccess(res.data.data.result));
    }).catch((error) => {
        dispatch(ticketFailure());

        if (error.response?.data.errors.message) {
            toast.error(error.response.data.errors.message);

            if (error.response.data.errors.message === 'Please login') {
                dispatchEmail(logout());

                navigate('/')
            }
        } else {
            toast.error('Try again!');
        }
    });
}