const PayReducer = (state, action) => {
    switch (action.type) {
        case "PAY_START":
            return {
                pay: null,
                isFetchingPay: true,
                error: false,
            };
        case "PAY_SUCCESS":
            return {
                pay: action.payload,
                isFetchingPay: false,
                error: false,
            };
        case "PAY_FAILURE":
            return {
                pay: null,
                isFetchingPay: false,
                error: true,
            };
        default:
            return { ...state };
    }
}

export default PayReducer;