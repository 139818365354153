import React, { useState, useContext, useEffect, useRef, forwardRef } from 'react';
import {
    Setting4,
    Notification,
    User,
    Sms,
    Call,
    Location,
    Cake,
    HambergerMenu,
    Edit
} from 'iconsax-react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useNavigate } from 'react-router-dom';
import { CountryDropdown } from 'react-country-region-selector';
import DatePicker from "react-datepicker";
import { toast } from 'react-toastify';

import './setting.scss';
import "react-datepicker/dist/react-datepicker.css";
import LoaderPage from '../../component/loaderPage/LoaderPage';
import fakeData from './data.json';

import { EmailContext } from '../../context/emailContext/EmailContext';
import { ProfileContext } from '../../context/profileContext/ProfileContext';
import { getProfile, addAvatar } from '../../context/profileContext/apiCalls';

const Setting = ({ menu, setMenu }) => {
    const [loading, setLoading] = useState(true);
    const [proLoading, setProLoading] = useState(true);
    const [newPro, setNewPro] = useState(false);
    const [imgPro, setImgPro] = useState(null);

    const data = fakeData.data;

    const { user, dispatch } = useContext(EmailContext);
    const { profile, dispatchProfile, isFetchingProfile } = useContext(ProfileContext);

    const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
        <input className='infoInput' onClick={onClick} ref={ref} value={value} />
    ));

    const navigate = useNavigate();

    const fetch = useRef(true);
    useEffect(() => {
        if (fetch.current) {
            fetch.current = false;

            getProfile(user.token, dispatchProfile, dispatch, navigate);
        }
    }, []);
    useEffect(() => {
        if (profile !== null) setNewPro(profile);
    }, [profile]);

    const handleUpload = () => {
        imgPro !== null ?
            addAvatar(imgPro, user.token, dispatchProfile, dispatch, navigate)
            :
            toast.error("Please choose an image")
    }

    return (
        <div className='setting'>
            <div className={menu ? 'mask' : 'mask close'} />

            <div className='topFirst'>
                <div className='left'>
                    <Setting4 className="icon" size={60} />
                    <div className='titleContain'>
                        <span className='mainTitle'>Setting</span>
                    </div>
                </div>
                <div className='right'>
                    <div className='rightContent'>
                        <button className='buyBtn'>Buy Robot</button>
                        <div className='profileContain'>
                            <div className='imgContain'>
                                <div className='img'>
                                    <img
                                        src={
                                            !loading ?
                                                window.$url + `/${user.avatar}`
                                                :
                                                require('../../images/avatar.png')}
                                        alt={user.fullName}
                                        onLoad={() => setLoading(false)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='infoContain'>
                            <span className='name'>
                                {user.fullName}
                            </span>
                            <span className='email'>
                                {user.email}
                            </span>
                        </div>
                        <div className='notifContain'>
                            <div className='combine'>
                                <Notification className='icon' size={20} />
                                <div className='dot' />
                            </div>
                        </div>
                    </div>
                </div>

                <div className='leftMobile'>
                    <HambergerMenu size="32" className='menuBtn' onClick={() => setMenu(true)} />
                </div>
                <div className='middleMobile'>
                    <Setting4 className="icon" size={30} />
                </div>
                <div className='rightMobile'>
                    <div className='profileContain'>
                        <div className='imgContain'>
                            <div className='img'>
                                <img
                                    src={
                                        !loading ?
                                            window.$url + `/${user.avatar}`
                                            :
                                            require('../../images/avatar.png')}
                                    alt={user.fullName}
                                    onLoad={() => setLoading(false)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='accountContain'>
                <span className='title'>Account</span>

                <div className='account'>
                    <div className='imgContain'>
                        <div className='imgAccountContain'>
                            <img
                                className='imgAccount'
                                src={!proLoading ?
                                    imgPro === null ?
                                        window.$url + `/${newPro.avatar}`
                                        :
                                        URL.createObjectURL(imgPro)
                                    :
                                    require('../../images/avatar.png')}
                                alt={user.fullName}
                                onLoad={() => setProLoading(false)}
                            />



                            <button className='selectImg' onClick={() => document.getElementById('img').click()}>
                                <Edit
                                    size="20"
                                    color={'#fff'}
                                />
                            </button>

                            <button className='uploadBtn' onClick={handleUpload}>Upload Avatar</button>
                        </div>
                    </div>

                    <div className='infoContain'>
                        <div className='info'>
                            <div className='infoHeader'>
                                <User className='headerIcon' size={23} />
                                <span className='headerTitle'>Name and Lastname</span>
                            </div>

                            <input
                                className='infoInput'
                                defaultValue={newPro.frist_name}
                                name='frist_name'
                                onChange={(e) => setNewPro({ ...newPro, [e.target.name]: e.target.value })}
                            />
                            <div className='verifyContain'>
                                <span className='verifyBtn' style={{ color: 'transparent', cursor: 'default' }}>a</span>
                            </div>
                        </div>

                        <div className='info'>
                            <div className='infoHeader'>
                                <Call className='headerIcon' size={23} />
                                <span className='headerTitle'>Phone Number</span>
                                <span className='notVerified'>Not verified</span>
                            </div>

                            <PhoneInput
                                inputExtraProps={{
                                    name: "phone",
                                    required: true,
                                    autoFocus: true
                                }}
                                defaultCountry={"ir"}
                                value={newPro?.mobile}
                                onChange={(e) => setNewPro({ ...newPro, ['mobile']: e })}
                                containerClass='phoneContain'
                                buttonClass='buttonClass'
                                inputClass='inputClass'
                                dropdownClass='dropdownClass'
                                searchClass='searchClass'
                                enableSearch={true}
                                disableSearchIcon={true}
                            />
                        </div>
                    </div>

                    <div className='infoContain'>
                        <div className='info'>
                            <div className='infoHeader'>
                                <Sms className='headerIcon' size={23} />
                                <span className='headerTitle'>Email Address</span>
                                <span className={newPro?.statusEmail ? 'verified' : 'notVerified'}>{newPro?.statusEmail ? 'Verified' : 'Not Verified'}</span>
                            </div>

                            <input className='infoInput' defaultValue={newPro?.email} />
                            <div style={{ color: 'transparent' }}>a</div>
                            {/* <div className='verifyContain'>
                                <span className='verifyBtn'>send activation link</span>
                            </div> */}
                        </div>

                        <div className='info'>
                            <div className='infoHeader'>
                                <Location className='headerIcon' size={23} />
                                <span className='headerTitle'>Country</span>
                            </div>

                            <CountryDropdown
                                className={'infoInput'}
                                value={newPro?.country}
                                onChange={(e) => setNewPro({ ...newPro, ['country']: e })}
                            />
                        </div>
                    </div>

                    <div className='infoContain'>
                        <div className='info'>
                            <div className='infoHeader'>
                                <Cake className='headerIcon' size={23} />
                                <span className='headerTitle'>Birthday Date</span>
                            </div>

                            <DatePicker
                                selected={Date.parse(newPro?.brithday)}
                                onChange={(e) => setNewPro({ ...newPro, ['brithday']: String(e) })}
                                customInput={<ExampleCustomInput />}
                                dateFormat="yyyy-MM-dd"
                                showMonthDropdown
                                showYearDropdown
                            />
                            <div className='verifyContain'>
                                <span className='verifyBtn' style={{ color: 'transparent', cursor: 'default' }}>a</span>
                            </div>
                        </div>

                        <div className='info'>

                        </div>
                    </div>

                    <div className='infoContain'>
                        <div className="addProductItem">
                            <input
                                type="file"
                                id="img"
                                name={"image"}
                                onChange={(e) => setImgPro(e.target.files[0])}
                                hidden
                            />
                        </div>
                    </div>
                </div>
            </div>

            <LoaderPage isFetching={isFetchingProfile} />
        </div>
    )
};

export default Setting;