import React from 'react';
import ReactDOM from 'react-dom/client';

import './index.css';
import App from './App';

import { EmailContextProvider } from './context/emailContext/EmailContext';
import { PlansContextProvider } from './context/plansContext/PlansContext';
import { NewsContextProvider } from './context/newContext/NewsContext';
import { BasketContextProvider } from './context/basketContext/BasketContext';
import { UserPackContextProvider } from './context/userPackContext/UserPackContext';
import { PayContextProvider } from './context/payContext/PayContext';
import { ProfileContextProvider } from './context/profileContext/ProfileContext';
import { TicketContextProvider } from './context/ticketContext/TicketContext';
import { TicketInfoContextProvider } from './context/ticketInfoContext/TicketInfoContext';
import { GoogleAuthContextProvider } from './context/googleAuthContext/GoogleAuthContext';
import { ReferralContextProvider } from './context/referralContext/ReferralContext';
import { ResetPassContextProvider } from './context/resetPassContext/ResetPassContext';
import { CommissionHisContextProvider } from './context/commissionHisContext/CommissionHisContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <EmailContextProvider>
      <PlansContextProvider>
        <NewsContextProvider>
          <BasketContextProvider>
            <UserPackContextProvider>
              <PayContextProvider>
                <ProfileContextProvider>
                  <TicketContextProvider>
                    <TicketInfoContextProvider>
                      <GoogleAuthContextProvider>
                        <ReferralContextProvider>
                          <ResetPassContextProvider>
                            <CommissionHisContextProvider>
                              <App />
                            </CommissionHisContextProvider>
                          </ResetPassContextProvider>
                        </ReferralContextProvider>
                      </GoogleAuthContextProvider>
                    </TicketInfoContextProvider>
                  </TicketContextProvider>
                </ProfileContextProvider>
              </PayContextProvider>
            </UserPackContextProvider>
          </BasketContextProvider>
        </NewsContextProvider>
      </PlansContextProvider>
    </EmailContextProvider>
  </React.StrictMode>
);