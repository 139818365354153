import React, { createContext, useReducer } from "react";

import PayReducer from "./PayReducer";

const INITIAL_STATE = {
    pay: null,
    isFetchingPay: false,
    error: false,
};

export const PayContext = createContext(INITIAL_STATE);

export const PayContextProvider = ({ children }) => {
    const [state, dispatchPay] = useReducer(PayReducer, INITIAL_STATE);

    return (
        <PayContext.Provider
            value={{
                pay: state.pay,
                isFetchingPay: state.isFetchingPay,
                error: state.error,
                dispatchPay
            }}
        >
            {children}
        </PayContext.Provider>
    )
}