import React, { useState, useContext, useRef, useEffect } from 'react';
import { Grid } from '@mui/material';
import {
    CpuSetting,
    Notification,
    HambergerMenu
} from 'iconsax-react';
import { useNavigate } from 'react-router-dom';

import './roboman.scss';
import fakeData from '../../data/fakeData.json';
import RoboCard from '../../component/roboCard/RoboCard';
import LoaderPage from '../../component/loaderPage/LoaderPage';

import { EmailContext } from '../../context/emailContext/EmailContext';
import { UserPackContext } from '../../context/userPackContext/UserPackContext';
import { getUserPacks } from '../../context/userPackContext/apiCalls';

const Roboman = ({ menu, setMenu }) => {
    const [loading, setLoading] = useState(true);

    const { user, dispatch } = useContext(EmailContext);
    const { userPack, dispatchUserPack, isFetchingUserPack } = useContext(UserPackContext);

    const data = fakeData.data;

    const navigate = useNavigate();

    const fetch = useRef(true);
    useEffect(() => {
        if (fetch.current) {
            fetch.current = false;

            getUserPacks(dispatchUserPack, user.token, navigate, dispatch);
        }
        console.log(userPack)
    }, []);

    return (
        <div className='roboman'>
            <div className={menu ? 'mask' : 'mask close'} />

            <div className='topFirst'>
                <div className='left'>
                    <CpuSetting className="icon" size={60} />
                    <div className='titleContain'>
                        <span className='mainTitle'>Roboman</span>
                        <span className='moreTitle'>Your robot center</span>
                    </div>
                </div>
                <div className='right'>
                    <div className='rightContent'>
                        <div className='profileContain'>
                            <div className='imgContain'>
                                <div className='img'>
                                    <img
                                        src={
                                            !loading ?
                                                window.$url + `/${user.avatar}`
                                                :
                                                require('../../images/avatar.png')}
                                        alt={user.fullName}
                                        onLoad={() => setLoading(false)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='infoContain'>
                            <span className='name'>
                                {user.fullName}
                            </span>
                            <span className='email'>
                                {user.email}
                            </span>
                        </div>
                        <div className='notifContain'>
                            <div className='combine'>
                                <Notification className='icon' size={20} />
                                <div className='dot' />
                            </div>
                        </div>
                    </div>
                </div>

                <div className='leftMobile'>
                    <HambergerMenu size="32" className='menuBtn' onClick={() => setMenu(true)} />
                </div>
                <div className='middleMobile'>
                    <CpuSetting className="icon" size={30} />
                </div>
                <div className='rightMobile'>
                    <div className='profileContain'>
                        <div className='imgContain'>
                            <div className='img'>
                                <img
                                    src={
                                        !loading ?
                                            window.$url + `/${user.avatar}`
                                            :
                                            require('../../images/avatar.png')}
                                    alt={user.fullName}
                                    onLoad={() => setLoading(false)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Grid container spacing={5}>
                {userPack?.map((item, index) => (
                    <RoboCard item={item} key={index} />
                ))}
            </Grid>

            <LoaderPage isFetching={isFetchingUserPack} />
        </div>
    )
};

export default Roboman;