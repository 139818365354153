import axios from "axios";
import { toast } from 'react-toastify';

import { resetPassFailure, resetPassStart, resetPassSuccess } from "./ResetPassActions";
import { logout } from '../emailContext/EmailActions';

export const sendResetEmail = async (token, setEmail, email, dispatch, dispatchEmail, navigate) => {
    dispatch(resetPassStart());

    axios.post(window.$url + "/api/auth/sendresetemail", { email }, {
        headers: {
            token
        }
    }).then((res) => {
        dispatch(resetPassSuccess(null));
        toast.success(res.data.data.message);
        setEmail(true);
    }).catch((error) => {
        dispatch(resetPassFailure());

        if (error.response?.data.errors.message) {
            toast.error(error.response.data.errors.message);

            if (error.response.data.errors.message === 'Please login') {
                dispatchEmail(logout());

                navigate('/')
            }
        } else {
            toast.error('Try again!');
        }
    });
}

export const verifyResetCode = async (token, email, code, setFirst, setSecond, dispatch, dispatchEmail, navigate) => {
    dispatch(resetPassStart());

    axios.post(window.$url + "/api/auth/validatereset", { email, code }, {
        headers: {
            token
        }
    }).then((res) => {
        dispatch(resetPassSuccess(null));
        toast.success(res.data.data.message);

        setFirst(false);
        setSecond(true);
    }).catch((error) => {
        dispatch(resetPassFailure());

        if (error.response?.data.errors.message) {
            toast.error(error.response.data.errors.message);

            if (error.response.data.errors.message === 'Please login') {
                dispatchEmail(logout());

                navigate('/')
            }
        } else {
            toast.error('Try again!');
        }
    });
}

export const resetPassSecurity = async (token, email, password, setEmail, setFirst, setSecond, dispatch, dispatchEmail, navigate) => {
    dispatch(resetPassStart());

    axios.post(window.$url + "/api/auth/changePassreset", { email, password }, {
        headers: {
            token
        }
    }).then((res) => {
        dispatch(resetPassSuccess(null));
        toast.success(res.data.data.message);

        setEmail(false);
        setFirst(true);
        setSecond(false);
    }).catch((error) => {
        dispatch(resetPassFailure());

        if (error.response?.data.errors.message) {
            toast.error(error.response.data.errors.message);

            if (error.response.data.errors.message === 'Please login') {
                dispatchEmail(logout());

                navigate('/')
            }
        } else {
            toast.error('Try again!');
        }
    });
}