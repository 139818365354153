import React from 'react';

import './aboutDialog.scss';

const AboutDialog = ({ setOpenAbout }) => {
    return (
        <div className='aboutDialog'>
            <div className='dialogContain'>
                <div className='contentContain'>
                    <span className='topic'>About Us</span>

                    <div className='main'>
                        <span className='lineContain'>
                            <span className='line'>Experience trading with artificial intelligence</span>
                        </span>

                        <span className='lineContain'>
                            <span className='line'>Trade with continuous profit and positive returns at fxtrader</span>
                        </span>
                    </div>

                    <button className='closeBtn' onClick={() => setOpenAbout(false)}>Close</button>
                </div>
            </div>
        </div>
    );
};

export default AboutDialog;