import React, { useState, useContext, useEffect, useRef } from 'react';
import VerificationInput from 'react-verification-input';
import {
    Key,
    Eye,
    EyeSlash,
    ArrowCircleRight,
    CloseSquare
} from 'iconsax-react';
import { toast } from 'react-toastify';
import BounceLoader from "react-spinners/BounceLoader";
import { css } from "@emotion/react";
import { useNavigate } from 'react-router-dom';

import './resetPassDialog.scss';
import { ResetPassContext } from '../../context/resetPassContext/ResetPassContext';
import { sendResetEmail, verifyResetCode, resetPassSecurity } from '../../context/resetPassContext/apiCalls';
import { EmailContext } from '../../context/emailContext/EmailContext';

const override = css`
  display: block;
`;

const EmailDialog = ({ setEmail }) => {
    const [first, setFirst] = useState(true);
    const [second, setSecond] = useState(false);
    const [showPass, setShowPass] = useState(false);
    const [code, setCode] = useState('');
    const [pass, setPass] = useState('');
    const [confPass, setConfPass] = useState('');

    const { user, dispatch, isFetching } = useContext(EmailContext);
    const { dispatchResetPass, isFetchingResetPass } = useContext(ResetPassContext);

    const passRef = useRef(null);
    const codeRef = useRef(null);

    const navigate = useNavigate();

    useEffect(() => {
        codeRef.current.focus();
    }, []);

    const handlePassKeypress = (e) => {
        if (e.charCode === 13) {
            handlePass();
        }
    };

    const handleCode = () => {
        if (code.length === 4) {
            verifyResetCode(user.token, user.email, code, setFirst, setSecond, dispatchResetPass, dispatch, navigate);
        } else {
            toast.error("Please enter your code correctly!");
        }
    }

    const handlePass = () => {
        if (pass !== '' && confPass !== '') {
            if (pass === confPass) {
                resetPassSecurity(user.token, user.email, pass, setEmail, setFirst, setSecond, dispatchResetPass, dispatch, navigate);
            } else {
                toast.error('Passwords are not matched!!!');
            }
        } else {
            toast.error("Please complete all fields!");
        }
    }

    return (
        <div className={'smsDialog'}>
            <div className='dialogContain'>
                <div className='closeBtn' onClick={() => {
                    setEmail(false);
                    setFirst(true);
                    setSecond(false);
                }}>
                    <CloseSquare size="32" />
                </div>

                <div className={first ? 'dialog' : 'dialog left'} >
                    <span className='title'>Change Password</span>
                    <span className='mainTxt'>Enter the 4-digit code send to you at: <span className='phoneTxt'>{user?.email}</span></span>

                    <VerificationInput
                        removeDefaultStyles
                        length={4}
                        validChars={'0-9'}
                        classNames={{
                            container: "varifyContain",
                            character: "varifyChar",
                            characterInactive: "character--inactive",
                            characterSelected: "charSelected",
                        }}
                        ref={codeRef}
                        onChange={(value) => setCode(value)}
                    />

                    <span className='sendAgain' disabled={isFetchingResetPass} onClick={() => sendResetEmail(user.token, setEmail, dispatchResetPass, dispatch, navigate)}>Send again</span>

                    <button className='btnValid' type='submit' onClick={handleCode} disabled={isFetchingResetPass}>
                        {!isFetchingResetPass ?
                            <>
                                Comfirm
                                <ArrowCircleRight color='#fff' size={20} variant='Bold' style={{ marginLeft: '5px' }} />
                            </>
                            :
                            <BounceLoader color={"#fff"} loading={true} css={override} size={15} />
                        }
                    </button>
                </div>

                <div className={second ? 'dialog' : 'dialog right'}>
                    <span className='title'>Reset Password</span>

                    <div className='inpTitleContain'>
                        <Key color='#fff' size={18} />

                        <span className='inpTitle'>New Password</span>
                    </div>

                    <div className='passContain'>
                        <input
                            className='loginInput'
                            type={showPass ? 'text' : 'password'}
                            ref={passRef}
                            onChange={(e) => setPass(e.target.value)}
                            onKeyPress={handlePassKeypress}
                        />
                        <div className='showPass' onClick={() => setShowPass(!showPass)}>
                            {
                                !showPass
                                    ?
                                    <EyeSlash color='#fff' size={18} style={{ fontSize: '16px' }} />
                                    :
                                    <Eye color='#fff' size={18} style={{ fontSize: '16px' }} />
                            }
                        </div>
                    </div>

                    <div className='inpTitleContain'>
                        <Key color='#fff' size={18} />

                        <span className='inpTitle'>Confirm Password</span>
                    </div>

                    <div className='passContain' style={{ marginBottom: '2rem' }}>
                        <input
                            className='loginInput'
                            type={showPass ? 'text' : 'password'}
                            onChange={(e) => setConfPass(e.target.value)}
                            onKeyPress={handlePassKeypress}
                        />
                    </div>

                    <button className='btnValid' onClick={handlePass} disabled={isFetchingResetPass}>
                        {!isFetchingResetPass ?
                            <>
                                Comfirm
                                <ArrowCircleRight color='#fff' size={20} variant='Bold' style={{ marginLeft: '5px' }} />
                            </>
                            :
                            <BounceLoader color={"#fff"} loading={true} css={override} size={15} />
                        }
                    </button>
                </div>
            </div>
        </div>
    );
};

export default EmailDialog;