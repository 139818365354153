import React, { useEffect, useState, forwardRef, useContext } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import Checkbox from '@mui/material/Checkbox';
import DatePicker from "react-datepicker";
import { toast } from 'react-toastify';
import {
    userData,
    Sms,
    Call,
    Location,
    Key,
    Cake,
    Eye,
    EyeSlash,
    ArrowCircleRight
} from 'iconsax-react';
import { CountryDropdown } from 'react-country-region-selector';
import BounceLoader from "react-spinners/BounceLoader";
import { css } from "@emotion/react";
import { useSearchParams, useNavigate } from 'react-router-dom';

import './register.scss';
import "react-datepicker/dist/react-datepicker.css";

import VerfyEmailDialog from '../../component/verifyEmailDialog/VerfyEmailDialog';
import PolicyDialog from '../../component/policyDialog/PolicyDialog';
import { sendEmail } from '../../context/emailContext/apiCalls';
import { EmailContext } from '../../context/emailContext/EmailContext';

const override = css`
  display: block;
`;

const Register = () => {
    const [checked, setChecked] = useState(false);
    const [email, setEmail] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [country, setCountry] = useState('');
    const [showPass, setShowPass] = useState(false);
    const [openPolicy, setOpenPolicy] = useState(false);
    const [userData, setUserData] = useState(null);

    const [params] = useSearchParams();
    const reflink = params.get('reflink') || null;

    const navigate = useNavigate();

    const { user, dispatch, isFetching } = useContext(EmailContext);

    useEffect(() => {
        if (user !== null) {
            navigate('/dashboard');
        }
    }, [user]);

    const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
        <button className='regCalBtn' onClick={onClick} ref={ref}>
            {value}
        </button>
    ));

    const handleChange = (e) => {
        const value = e.target.value;

        setUserData({ ...userData, [e.target.name]: value });
    }
    const handlePhoneChange = (e) => {
        const value = e;

        setUserData({ ...userData, ['mobile']: value });
    }
    const handleCountryChange = (val) => {
        const value = val;

        setUserData({ ...userData, ['country']: value });
        setCountry(val);
    }
    const handleBirthChange = (e) => {
        const value = e;

        setUserData({ ...userData, ['brithday']: String(e) });
        setStartDate(e);
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (checked) {
            if (userData !== null) {
                if (userData.frist_name === undefined || userData.email === undefined || userData.mobile === undefined || userData.country === undefined || userData.password === undefined || userData.brithday === undefined || userData.frist_name === '' || userData.email === '' || userData.mobile === '' || userData.country === '' || userData.password === '' || userData.brithday === '') {
                    alert("Please complete all fields!");
                } else {
                    if (userData.password.length >= 4 && userData.password.length <= 16)
                        sendEmail(setEmail, userData.email, dispatch);
                    else
                        toast.error('Password length should be between 4 - 16 !!!');
                }
            } else {
                alert("Please complete all fields!");
            }
        } else {
            alert('You are not agree with our policies!!!');
        }
    }

    return (
        <div className='register'>
            <div className='regContain'>
                <div className='regLeft'>
                    <span className='mainTitle'>Welcome,</span>
                    <div className='regTitleContain'>
                        To
                        <img className='fxLogo' src={require('../../images/logo.png')} alt={'Fx logo'} />
                        Trader
                    </div>

                    <div className='sectionContain'>
                        <div className='section'>
                            <div className='titleContain'>
                                <userData color="#ffffff" className='titleIcon' />
                                <span className='title'>Name and Lastname</span>
                            </div>

                            <div className='regInpContain'>
                                <input
                                    className='regInp'
                                    name='frist_name'
                                    placeholder='Enter your name and lastname'
                                    onChange={handleChange} />
                            </div>
                        </div>
                        <div className='section'>
                            <div className='titleContain'>
                                <Sms color="#ffffff" className='titleIcon' />
                                <span className='title'>Email Address</span>
                            </div>

                            <div className='regInpContain'>
                                <input
                                    className='regInp'
                                    name='email'
                                    placeholder='Enter your email address'
                                    autoComplete="new-password"
                                    onChange={handleChange} />
                            </div>
                        </div>
                    </div>

                    <div className='sectionContain'>
                        <div className='section'>
                            <div className='titleContain'>
                                <Call color="#ffffff" className='titleIcon' />
                                <span className='title'>Phone Number</span>
                            </div>

                            <PhoneInput
                                inputExtraProps={{
                                    name: "phone",
                                    required: true,
                                    autoFocus: true
                                }}
                                country={"ir"}
                                // value={value}
                                onChange={handlePhoneChange}
                                containerClass='phoneContain'
                                buttonClass='buttonClass'
                                inputClass='inputClass'
                                dropdownClass='dropdownClass'
                                searchClass='searchClass'
                                enableSearch={true}
                                disableSearchIcon={true}
                            />
                        </div>
                        <div className='section'>
                            <div className='titleContain'>
                                <Location color="#ffffff" className='titleIcon' />
                                <span className='title'>Country</span>
                            </div>

                            <div className='regInpContain'>
                                <CountryDropdown
                                    className={'regCountry'}
                                    value={country}
                                    onChange={handleCountryChange}
                                />
                                {/* <input
                                    className='regInp'
                                    placeholder='Country' /> */}
                            </div>
                        </div>
                    </div>

                    <div className='sectionContain'>
                        <div className='section'>
                            <div className='titleContain'>
                                <Key color="#ffffff" className='titleIcon' />
                                <span className='title'>Password</span>
                            </div>

                            <div className='regInpContain'>
                                <input
                                    className='regInp'
                                    type={showPass ? 'text' : 'password'}
                                    name='password'
                                    onChange={handleChange}
                                    autoComplete="new-password"
                                    placeholder='Enter your password' />

                                <div className='showPass' onClick={() => setShowPass(!showPass)}>
                                    {
                                        !showPass
                                            ?
                                            <EyeSlash className='regIcon' color='#fff' />
                                            :
                                            <Eye className='regIcon' color='#fff' />
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='section'>
                            <div className='titleContain'>
                                <Cake color="#ffffff" className='titleIcon' />
                                <span className='title'>Birthday Date</span>
                            </div>

                            <div className='regInpContain'>
                                <DatePicker
                                    selected={startDate}
                                    // onChange={(date) => setStartDate(date)}
                                    onChange={handleBirthChange}
                                    customInput={<ExampleCustomInput />}
                                    dateFormat="yyyy-MM-dd"
                                    showMonthDropdown
                                    showYearDropdown
                                />
                                {/* <input
                                    className='regInp'
                                    placeholder='0000-00-00' /> */}
                            </div>
                        </div>
                    </div>

                    <div className='checkContain' onClick={() => setChecked(!checked)}>
                        <Checkbox
                            checked={checked}
                            sx={{
                                padding: 0,
                                color: '#13CC68',
                                '&.Mui-checked': {
                                    color: '#13CC68',
                                },
                            }}
                        />
                        <span className='checkLabel'>I agree to <span className='prev' onClick={() => setOpenPolicy(true)}>Privacy Policy</span></span>
                    </div>

                    <button className='btn' onClick={handleSubmit} disabled={!checked || isFetching}>
                        {!isFetching ?
                            <>
                                Register
                                <ArrowCircleRight color='#fff' size={20} variant='Bold' style={{ marginLeft: '5px' }} />
                            </>
                            :
                            <BounceLoader color={"#fff"} loading={true} css={override} size={15} />
                        }
                    </button>
                </div>

                <div className='regRight'>
                    <img className='regCover' src={require('../../images/loginCover.png')} alt='Fx Login Cover' />
                </div>
            </div>

            <VerfyEmailDialog
                email={email}
                setEmail={setEmail}
                userDataEmail={userData && userData.email}
                userData={userData && userData}
                reflink={reflink}
            />

            <PolicyDialog openPolicy={openPolicy} setOpenPolicy={setOpenPolicy} />
        </div>
    );
};

export default Register;