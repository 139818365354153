import React, { createContext, useReducer } from "react";

import BasketReducer from "./BasketReducer";

const INITIAL_STATE = {
    basket: null,
    isFetchingBasket: false,
    error: false,
};

export const BasketContext = createContext(INITIAL_STATE);

export const BasketContextProvider = ({ children }) => {
    const [state, dispatchBasket] = useReducer(BasketReducer, INITIAL_STATE);

    return (
        <BasketContext.Provider
            value={{
                basket: state.basket,
                isFetchingBasket: state.isFetchingBasket,
                error: state.error,
                dispatchBasket
            }}
        >
            {children}
        </BasketContext.Provider>
    )
}