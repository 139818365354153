const UserPackReducer = (state, action) => {
    switch (action.type) {
        case "USERPACK_START":
            return {
                userPack: null,
                isFetchingUserPack: true,
                error: false,
            };
        case "USERPACK_SUCCESS":
            return {
                userPack: action.payload,
                isFetchingUserPack: false,
                error: false,
            };
        case "USERPACK_FAILURE":
            return {
                userPack: null,
                isFetchingUserPack: false,
                error: true,
            };
        default:
            return { ...state };
    }
}

export default UserPackReducer;