import axios from "axios";
import { toast } from 'react-toastify';

import { commissionHisFailure, commissionHisStart, commissionHisSuccess } from "./CommissionHisActions";
import { logout } from '../emailContext/EmailActions';

export const getCommissionHis = async (token, dispatch, dispatchEmail, navigate) => {
    dispatch(commissionHisStart());

    axios.get(window.$url + "/api/user/referral/list", {
        headers: {
            token
        }
    }).then((res) => {
        dispatch(commissionHisSuccess(res.data.data));
    }).catch((error) => {
        dispatch(commissionHisFailure());

        if (error.response?.data.errors.message) {
            toast.error(error.response.data.errors.message);

            if (error.response.data.errors.message === 'Please login') {
                dispatchEmail(logout());

                navigate('/')
            }
        } else {
            toast.error('Try again!');
        }
    });
}

export const sendWithdraw = async (token, dispatch, dispatchEmail, navigate, amount, address, setOpenWithdraw) => {
    dispatch(commissionHisStart());

    axios.post(window.$url + "/api/user/referral/withdraw", {amount, address}, {
        headers: {
            token
        }
    }).then((res) => {
        getCommissionHis(token, dispatch, dispatchEmail, navigate);
        toast.success(res.data.data.message);
        setOpenWithdraw(false);
    }).catch((error) => {
        dispatch(commissionHisFailure());

        if (error.response?.data.errors.message) {
            toast.error(error.response.data.errors.message);

            if (error.response.data.errors.message === 'Please login') {
                dispatchEmail(logout());

                navigate('/')
            }
        } else {
            toast.error('Try again!');
        }
    });
}