import React, { useEffect, useState, useRef, useContext } from 'react';
import {
    DoubleArrow,
    ArrowForwardIos,
    Facebook,
    Instagram,
    YouTube,
    Twitter,
    LinkedIn
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import {
    Diagram,
    Cpu,
    ShieldTick
} from 'iconsax-react';
import BounceLoader from "react-spinners/BounceLoader";
import { css } from "@emotion/react";
import axios from 'axios';

import './home.scss';
import Header from '../../component/header/Header';
import Menu from '../../component/menu/Menu';
import AboutDialog from '../../component/aboutDialog/AboutDialog';
import { EmailContext } from '../../context/emailContext/EmailContext';
import { PlansContext } from '../../context/plansContext/PlansContext';
import { NewsContext } from '../../context/newContext/NewsContext';
import { getPlans } from '../../context/plansContext/apiCalls';
import { getNews } from '../../context/newContext/apiCalls';
import PlanCard from '../../component/planCard/PlanCard';
import NewsCard from '../../component/newsCard/NewsCard';

const override = css`
display: block;
`;

const Home = () => {
    const [tr, setTr] = useState(false);
    const [tr2, setTr2] = useState(false);
    const [open, setOpen] = useState(false);
    const [openAbout, setOpenAbout] = useState(false);

    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const ref3 = useRef(null);
    const ref4 = useRef(null);

    const { user, dispatch } = useContext(EmailContext);
    const { plans, dispatchPlans, isFetching } = useContext(PlansContext);
    const { news, dispatchNews, isFetchingNews } = useContext(NewsContext);

    const navigate = useNavigate();

    const fetch = useRef(true);
    useEffect(() => {
        if (fetch.current) {
            fetch.current = false;

            getPlans(dispatchPlans);
            getNews(dispatchNews);
        }
    }, []);

    useEffect(() => {
        timmer();
        timmer2();
    });

    const timmer = () => {
        setTimeout(() => {
            setTr(!tr);
        }, 1500);
    }
    const timmer2 = () => {
        setTimeout(() => {
            setTr2(!tr2);
        }, 500);
    }

    const handleScroll = (type) => {
        switch (type) {
            case 'first':
                ref1.current?.scrollIntoView({ behavior: 'smooth' });
                break;
            case 'second':
                ref2.current?.scrollIntoView({ behavior: 'smooth' });
                break;
            case 'third':
                ref3.current?.scrollIntoView({ behavior: 'smooth' });
                break;
            case 'forth':
                ref4.current?.scrollIntoView({ behavior: 'smooth' });
                break;
            default:
                break;
        }
    };

    const testEmail = () => {
        axios.get("http://localhost:3030/api/auth/testemail").then((res) => {
            console.log(res.data);
        }).catch((error) => {
            if (error.response.data.errors.message) {
                console.log(error.response.data.errors.message);
            } else {
                console.log('Try again!');
            }
        });
    }

    return (
        <>
            <Header handleScroll={handleScroll} open={open} setOpen={setOpen} openAbout={openAbout} setOpenAbout={setOpenAbout} />
            <Menu handleScroll={handleScroll} open={open} setOpen={setOpen} />

            <div className='home'>
                <div className='mainContain'>
                    <div className='topContain'>
                        <div className='top'>
                            <div className='left'>
                                <div className='anim'>
                                    <div className='engulf1'>
                                        <div className={tr ? 'engulf' : 'engulf close'} />
                                    </div>
                                    <div className='engulf2'>
                                        <div className={tr ? 'engulf' : 'engulf close'} />
                                    </div>
                                </div>
                                <span className='span1'>Forex Intelligent</span>
                                <span className='span2'>Trading Robot</span>

                                <div className='off'>
                                    <div className='offMount'>
                                        10% OFF
                                    </div>
                                    For this weekend
                                </div>

                                <span className='span3'>You can start trading in the world of forex</span>

                                <div
                                    className='btn'
                                    onClick={() => {
                                        user ?
                                            navigate('/dashboard', { replace: false })
                                            :
                                            navigate('/login', { replace: false })
                                    }}
                                >
                                    Let's Start
                                    <div className='icon'>
                                        <ArrowForwardIos style={{ fontSize: '15px' }} />
                                    </div>
                                </div>
                            </div>
                            <div className='right'>
                                <img src={require('../../images/cover.png')} alt={'opofinanceCover'} />
                            </div>
                        </div>
                        <div className={tr2 ? 'arrow' : 'arrow close'}>
                            <DoubleArrow style={{ fontSize: '45px' }} />
                        </div>
                    </div>

                    <div className='middleContain'>
                        <span className='span1' onClick={testEmail}>Why you should choose us?</span>
                        <div className='signContain'>
                            <div className={tr ? 'sign' : 'sign close'} />
                        </div>
                        <div className='middleBottom'>
                            <div className='card'>
                                <div className='topicContain'>
                                    <Diagram size="35" color="#13cc68" />
                                    <div className='topic'>High Efficiency</div>
                                </div>

                                <div className='body'>
                                    Weekly outcome 2 to 15% transaction only on XAUUSD
                                </div>
                            </div>
                            <div className='card'>
                                <div className='topicContain'>
                                    <ShieldTick size="35" color="#13cc68" />
                                    <div className='topic'>High Security</div>
                                </div>

                                <div className='body'>
                                    A secure platform for trading in the world of Forex
                                </div>
                            </div>
                            <div className='card'>
                                <div className='topicContain'>
                                    <Cpu size="35" color="#13cc68" />
                                    <div className='topic'>Artificial Intelligence</div>
                                </div>

                                <div className='body'>
                                    Weekly outcome 2 to 15% transaction only on XAUUSD
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='middleContain2' ref={ref1}>
                        <span className='span1'>Our pricing panel</span>
                        <div className='signContain'>
                            <div className={tr ? 'sign' : 'sign close'} />
                        </div>
                        <div className='middleBottom'>
                            {!isFetching ?
                                plans?.map((item, index) => <PlanCard item={item} index={index} />)
                                :
                                <BounceLoader
                                    color={"#fff"}
                                    loading={true}
                                    css={override}
                                    size={60}
                                />
                            }
                        </div>
                    </div>

                    <div className='middleContain3' ref={ref2}>
                        <div className='middle3'>
                            <img className='imgPhone' src={require('../../images/phoneImg.png')} alt='bubble' />
                            <div className='content'>
                                <div className='appIconContain'>
                                    <img className='appIcon' src={require('../../images/apple.png')} alt='bubble' />
                                    <img className='appIcon' src={require('../../images/android.png')} alt='bubble' />
                                </div>
                                <div className='txtContain'>
                                    <span className='solid1'>You can use our</span>
                                    <span className='solid2'>Application</span>
                                </div>
                                <div className='txtContain'>
                                    <span className='solid3'>For</span>
                                    <span className='solid4'>easy</span>
                                    <span className='solid3'>and</span>
                                    <span className='solid4'>fast access</span>
                                </div>
                            </div>
                            <div className='inner'>
                                <img className='img1' src={require('../../images/bubble.png')} alt='bubble' />
                                <img className='img2' src={require('../../images/bubble.png')} alt='bubble' />
                                <img className='img3' src={require('../../images/bubble.png')} alt='bubble' />
                            </div>
                        </div>
                    </div>

                    <div className='middleContain4'>
                        <span className='span1'>Why you should choose us?</span>
                        <div className='signContain'>
                            <div className={tr ? 'sign' : 'sign close'} />
                        </div>
                        <div className='middleBottom' ref={ref3}>
                            {!isFetchingNews ?
                                news?.map((item, index) => <NewsCard item={item} index={index} />)
                                :
                                <BounceLoader
                                    color={"#fff"}
                                    loading={true}
                                    css={override}
                                    size={60}
                                />
                            }
                        </div>
                    </div>
                    <div className='middleContain5'>
                        <span className='blog'>Visit Our Blog</span>
                        <ArrowForwardIos className='blogIcon' />
                    </div>
                </div>

                <div className='footer' ref={ref4}>
                    <div className='footerTop'>
                        <div className='footerInner1'>
                            <img className='footerLogo' src={require('../../images/logo.png')} alt='fxLogo' />
                            <span className='footerTxt'>Forex Intelligent</span>
                            <span className='footerTxtBold'>Trading Robot</span>

                            <div className='social'>
                                <div className='leftSocial'>

                                    <a
                                        rel="noreferrer"
                                        href={'https://www.facebook.com/profile.php?id=100085683401081'}
                                        target="_blank"
                                    >
                                        <Facebook className='socialIcon' />
                                    </a>
                                    <a
                                        rel="noreferrer"
                                        href={'https://instagram.com/fxtrader.global?igshid=NTc4MTIwNjQ2YQ=='}
                                        target="_blank"
                                    >
                                        <Instagram className='socialIcon' />
                                    </a>
                                </div>
                                <div className='rightSocial'>
                                    <a
                                        rel="noreferrer"
                                        href={'https://youtube.com/@fxtrader5125'}
                                        target="_blank"
                                    >
                                        <YouTube className='socialIcon' />
                                    </a>
                                    <a
                                        rel="noreferrer"
                                        href={'https://twitter.com/info_fxtrader/with_replies'}
                                        target="_blank"
                                    >
                                        <Twitter className='socialIcon' />
                                    </a>
                                    <a
                                        rel="noreferrer"
                                        href={'http://linkedin.com/in/fx-trader-bot-baa661263'}
                                        target="_blank"
                                    >
                                        <LinkedIn className='socialIcon' />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className='footerInner2'>
                            <span className='footerTopic'>Quick Link</span>
                            <span className='footerTxt'>Home</span>
                            <span className='footerTxt'>Products</span>
                            <span className='footerTxt'>About</span>
                            <span className='footerTxt'>Features</span>
                            <span className='footerTxt'>Contact</span>
                        </div>
                        <div className='footerInner2'>
                            <span className='footerTopic'>Resources</span>
                            <span className='footerTxt'>Test link</span>
                            <span className='footerTxt'>Test link</span>
                            <span className='footerTxt'>Test link</span>
                            <span className='footerTxt'>Test link</span>
                            <span className='footerTxt'>Test link</span>
                            1
                        </div>
                        <div className='footerInner3'>
                            <span className='payment'>We accept following</span>
                            <span className='payment'>payment system</span>

                            <div className='paymentBtn'>
                                <img className='icon' src={require('../../images/tether.png')} alt={'tether'} />
                                USDT
                            </div>
                        </div>
                    </div>
                </div>

                {openAbout &&
                    <AboutDialog setOpenAbout={setOpenAbout} />
                }
            </div>
        </>
    )
}

export default Home;