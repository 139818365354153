import React, { useState, useContext } from 'react';
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';

import './header.scss';
import { EmailContext } from '../../context/emailContext/EmailContext';

const Header = ({ handleScroll, open, setOpen, openAbout, setOpenAbout }) => {
  const { user } = useContext(EmailContext);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  return (
    <div className='header'>
      <div className='contain'>
        <div className='left'>
          <img onClick={() => user && navigate('/dashboard')} src={require('../../images/logo.png')} alt={'opofinanceLogo'} />
        </div>
        <div className='right'>
          <button className='btn' onClick={() => handleScroll('first')}>
            Plans
          </button>
          <button className='btn' onClick={() => handleScroll('second')}>
            Application
          </button>
          <button className='btn' onClick={() => handleScroll('third')}>
            Blog
          </button>
          <button className='btn' onClick={() => handleScroll('forth')}>
            Contact
          </button>
          <button className='btn' onClick={() => setOpenAbout(true)}>
            About
          </button>
          {user ?
            <div className='rightContent'>
              <div className='infoContain'>
                <span className='name'>
                  {user.fullName}
                </span>
                <span className='email'>
                  {user.email}
                </span>
              </div>
              <div className='profileContain'>
                <div className='imgContain'>
                  <div className='img'>
                    <img
                      src={
                        !loading ?
                          window.$url + user.avatar
                          :
                          require('../../images/avatar.png')}
                      alt={user.fullName}
                      onLoad={() => setLoading(false)}
                    />
                  </div>
                </div>
              </div>
            </div>
            :
            <>
              <Link to="/login" className="link">
                <button className='btn'>
                  Login
                </button>
              </Link>
              <div className='seperate' />
              <Link to="/register" className="link">
                <button className='regBtn'>
                  Register
                </button>
              </Link>
            </>
          }
        </div>

        <div className='mobileLeft'>
          <div className="btnContainer">
            <button className={'btn'} onClick={() => setOpen(!open)}>
              <div className={open ? "first" : "firstClose"} />
              <div className={open ? "second" : "secondClose"} />
            </button>
          </div>
        </div>

        <div className='mobileMiddle'>
          <img src={require('../../images/logo.png')} alt={'opofinanceLogo'} />
        </div>

        <div className='mobileRight' >
          h
        </div>
      </div>
    </div>
  )
}

export default Header;