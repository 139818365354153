import axios from "axios";
import { toast } from 'react-toastify';

import { googleAuthFailure, googleAuthStart, googleAuthSuccess } from "./GoogleAuthActions";

import { logout } from '../emailContext/EmailActions';

export const createGoogleAuth = async (token, dispatch, dispatchEmail, navigate) => {
    dispatch(googleAuthStart());

    axios.post(window.$url + "/api/user/googleauth/create", {}, {
        headers: {
            token
        }
    }).then((res) => {
        dispatch(googleAuthSuccess(res.data.data));
        toast.success(res.data.data.message);
    }).catch((error) => {
        dispatch(googleAuthFailure());

        if (error.response?.data.errors.message) {
            toast.error(error.response.data.errors.message);

            if (error.response.data.errors.message === 'Please login') {
                dispatchEmail(logout());

                navigate('/')
            }
        } else {
            toast.error('Try again!');
        }
    });
}

export const checkGoogleAuth = async (token, dispatch, dispatchEmail, navigate, setIsTwoStep, setIsAuth, setPage, code, setAuthCode) => {
    dispatch(googleAuthStart());

    axios.post(window.$url + "/api/user/googleauth/chaek", { code }, {
        headers: {
            token
        }
    }).then((res) => {
        console.log(res.data)
        dispatch(googleAuthSuccess(null));
        toast.success(res.data.data.message);
        setIsTwoStep(true);
        setIsAuth(false);
        setPage(1);
        setAuthCode(null);
    }).catch((error) => {
        dispatch(googleAuthFailure());

        if (error.response?.data.errors.message) {
            toast.error(error.response.data.errors.message);

            if (error.response.data.errors.message === 'Please login') {
                dispatchEmail(logout());

                navigate('/')
            }
        } else {
            toast.error('Try again!');
        }
    });
}

export const disableGoogleAuth = async (token, dispatch, dispatchEmail, navigate) => {
    dispatch(googleAuthStart());

    axios.post(window.$url + "/api/user/googleauth/disable", {}, {
        headers: {
            token
        }
    }).then((res) => {
        dispatch(googleAuthSuccess());
        toast.success(res.data.message);
    }).catch((error) => {
        dispatch(googleAuthFailure());

        if (error.response?.data.errors.message) {
            toast.error(error.response.data.errors.message);

            if (error.response.data.errors.message === 'Please login') {
                dispatchEmail(logout());

                navigate('/')
            }
        } else {
            toast.error('Try again!');
        }
    });
}