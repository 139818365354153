const ResetPassReducer = (state, action) => {
    switch (action.type) {
        case "RESETPASS_START":
            return {
                resetPass: null,
                isFetchingResetPass: true,
                error: false,
            };
        case "RESETPASS_SUCCESS":
            return {
                resetPass: action.payload,
                isFetchingResetPass: false,
                error: false,
            };
        case "RESETPASS_FAILURE":
            return {
                resetPass: null,
                isFetchingResetPass: false,
                error: true,
            };
        default:
            return { ...state };
    }
}

export default ResetPassReducer;