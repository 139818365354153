import React, { useContext, useState, useEffect } from 'react';
import VerificationInput from 'react-verification-input';
import { ArrowCircleRight, CloseSquare } from 'iconsax-react';
import BounceLoader from "react-spinners/BounceLoader";
import { useNavigate } from "react-router-dom";

import './verfyEmailDialog.scss';
import { css } from "@emotion/react";

import { EmailContext } from '../../context/emailContext/EmailContext';
import { sendEmail, verifyEmail } from '../../context/emailContext/apiCalls';

const override = css`
  display: block;
`; 

const VerfyEmailDialog = ({ email, setEmail, userDataEmail, userData, reflink }) => {
    const [code, setCode] = useState('');

    const { user, dispatch, isFetching } = useContext(EmailContext);

    let navigate = useNavigate();

    return (
        <div className={email ? 'verifyDialog' : 'verifyDialog close'}>
            <div className={'dialogContain'}>
                <div className='closeBtn' onClick={() => setEmail(false)}>
                    <CloseSquare size="32" />
                </div>
                <div className={'dialog'}>
                    <span className='title'>Verifying your email</span>
                    <span className='mainTxt'>Enter the 4-digit code send to you at:</span>
                    <span className='phoneTxt'>{userDataEmail}</span>

                    <VerificationInput
                        removeDefaultStyles
                        value={code}
                        length={4}
                        onChange={((value) => setCode(value))}
                        classNames={{
                            container: "varifyContain",
                            character: "varifyChar",
                            characterInactive: "character--inactive",
                            characterSelected: "charSelected",
                        }}
                    />

                    <span className='sendAgain' disabled={isFetching} onClick={() => sendEmail(setEmail, userDataEmail, dispatch)}>Send again</span>

                    <button className='btnValid' disabled={isFetching} onClick={() => verifyEmail(userData, code, setEmail, setCode, dispatch, navigate, reflink)}>
                        Comfirm
                        <ArrowCircleRight size="18" color="#ffffff" variant="Bold" style={{ marginLeft: '5px' }} />
                    </button>
                </div>

                <div className={isFetching ? 'loadContain' : 'loadContain close'}>
                    <BounceLoader color={"#fff"} loading={true} css={override} size={50} />
                </div>
            </div>
        </div>
    );
};

export default VerfyEmailDialog;