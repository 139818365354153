import React, { useState, useContext } from 'react';
import {
    Category2,
    CpuSetting,
    Scan,
    Shield,
    Setting4,
    People,
    MessageQuestion,
    LogoutCurve,
    Bag2,
    CloseSquare,
    ArchiveBook
} from 'iconsax-react';
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';

import './sidebar.scss';
import { EmailContext } from '../../context/emailContext/EmailContext';
import { logout } from '../../context/emailContext/EmailActions';

const Sidebar = ({ menu, setMenu }) => {
    const [current, setCurrent] = useState(window.location.pathname === '/' ? '/home' : window.location.pathname);
    const { user, dispatch } = useContext(EmailContext);

    const navigate = useNavigate();

    return (
        <div className={menu ? "sidebar" : 'sidebar close'}>
            <div className='closeBtn' onClick={() => setMenu(false)}>
                <CloseSquare size="32" />
            </div>

            <div className="sidebarWrapper">
                <div className="sidebarMenu">
                    <div className='logoContain'>
                        <img className='logo' src={require('../../images/logo.png')} alt={'opofinanceLogo'} />

                        <div className='infoContain'>
                            <span className='name'>
                                {user.fullName}
                            </span>
                            <span className='email'>
                                {user.email}
                            </span>
                        </div>
                    </div>

                    <ul className="sidebarList">
                        <Link to="/dashboard" className="link" onClick={() => { setCurrent('/dashboard'); setMenu(false) }}>
                            <li className={current === '/dashboard' ? "sidebarListItem active" : "sidebarListItem"}>
                                <Category2 className="sidebarIcon" size={20} />
                                Dashboard
                            </li>
                        </Link>
                        <Link to="/roboman" className="link" onClick={() => { setCurrent('/roboman'); setMenu(false) }}>
                            <li className={current === '/roboman' ? "sidebarListItem active" : "sidebarListItem"}>
                                <CpuSetting className="sidebarIcon" size={20} />
                                Roboman
                            </li>
                        </Link>
                        {/* <Link to="/verify" className="link" onClick={() => {setCurrent('/verify'); setMenu(false)}}>
                            <li className={current === '/verify' ? "sidebarListItem active" : "sidebarListItem"}>
                                <Scan className="sidebarIcon" size={20} />
                                Verify
                            </li>
                        </Link> */}
                        <Link to="/shopping" className="link" onClick={() => { setCurrent('/shopping'); setMenu(false) }}>
                            <li className={current === '/shopping' ? "sidebarListItem active" : "sidebarListItem"}>
                                <Bag2 className="sidebarIcon" size={20} />
                                Shopping
                            </li>
                        </Link>
                        <Link to="/plans" className="link" onClick={() => { setCurrent('/plans'); setMenu(false) }}>
                            <li className={current === '/plans' ? "sidebarListItem active" : "sidebarListItem"}>
                                <ArchiveBook className="sidebarIcon" size={20} />
                                Plans
                            </li>
                        </Link>
                        <Link to="/security" className="link" onClick={() => { setCurrent('/security'); setMenu(false) }}>
                            <li className={current === '/security' ? "sidebarListItem active" : "sidebarListItem"}>
                                <Shield className="sidebarIcon" size={20} />
                                Security
                            </li>
                        </Link>
                        <Link to="/setting" className="link" onClick={() => { setCurrent('/setting'); setMenu(false) }}>
                            <li className={current === '/setting' ? "sidebarListItem active" : "sidebarListItem"}>
                                <Setting4 className="sidebarIcon" size={20} />
                                Setting
                            </li>
                        </Link>
                        <Link to="/support" className="link" onClick={() => { setCurrent('/support'); setMenu(false) }}>
                            <li className={current === '/support' ? "sidebarListItem active" : "sidebarListItem"}>
                                <MessageQuestion className="sidebarIcon" size={20} />
                                Support
                            </li>
                        </Link>
                        <Link to="/referral" className="link" onClick={() => { setCurrent('/referral'); setMenu(false) }}>
                            <li className={current === '/referral' ? "sidebarListItem active" : "sidebarListItem"}>
                                <People className="sidebarIcon" size={20} />
                                Referral
                            </li>
                        </Link>
                        <li className="exitListItem" onClick={() => {
                            dispatch(logout());
                            navigate('/');
                        }}>
                            <LogoutCurve className="exitIcon" size={20} />
                            Exit
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
};

export default Sidebar;