import React, { createContext, useReducer } from "react";

import UserPackReducer from "./UserPackReducer";

const INITIAL_STATE = {
    userPack: null,
    isFetchingUserPack: false,
    error: false,
};

export const UserPackContext = createContext(INITIAL_STATE);

export const UserPackContextProvider = ({ children }) => {
    const [state, dispatchUserPack] = useReducer(UserPackReducer, INITIAL_STATE);

    return (
        <UserPackContext.Provider
            value={{
                userPack: state.userPack,
                isFetchingUserPack: state.isFetchingUserPack,
                error: state.error,
                dispatchUserPack
            }}
        >
            {children}
        </UserPackContext.Provider>
    )
}