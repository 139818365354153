import axios from "axios";
import { toast } from 'react-toastify';

import { newsFailure, newsStart, newsSuccess } from "./NewsAction";
import { logout } from '../emailContext/EmailActions';

export const getNews = async (dispatchNews) => {
    dispatchNews(newsStart());

    axios.get(window.$url + "/api/developer/news-list").then((res) => {
        dispatchNews(newsSuccess(res.data.data.result));
    }).catch((error) => {
        dispatchNews(newsFailure());

        if (error.response.data.errors.message) {
            toast.error(error.response.data.errors.message);
        } else {
            toast.error('Try again!');
        }
    });
}

export const logoutAdmin = async (dispatch) => {
    dispatch(logout());
}